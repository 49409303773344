div#main-wrapper {
  div#main {
    div#content-outer {
      & > div, aside {
        flex-grow: 1;
        flex-shrink: 0;
      }

      aside#sidebar-first,
      aside#sidebar-second {
        flex-grow: 0;
        flex-shrink: 0;
        float: none !important;
        margin: 20px 0 !important;
        padding: 0;
        width: 20%;

        @media screen and (max-width: $screen-sm) {
          margin: 0 !important;
          width: 100%;
        }

        div.region {
          section.block {
            background: transparentize(white, .15);
            margin-top: 15px;

            &:first-of-type {
              margin-top: 0;
            }

            h2 {
              background: $gray-lighter;
              color: $gray-base;
              font-size: $font-size-small * 1.1;
              font-weight: 600;
              padding: 8px 10px 8px;
              margin: 8px 0 8px;
              position: relative;
              &:first-letter {
                font-size: $font-size-small * 1.3;
              }
            }

            div.content {
              padding: 0 15px 10px;
            }

            &.anuncios-imagen {
              h2 {
                display: none;
                margin: 0;
              }

              div.content {
                padding: 0;
              }

              p {
                display: none;
                body.not-front & {
                  display: block !important;
                }
              }
            }

            &#block-views-agenda-prox-eventos-lateral {
              padding: 0;
              div.content {
                padding: 0 15px;
                div.views-row {
                  div.views-field-field-foto {
                    background: url("../images/logo.png");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: auto 50px;
                    border: 1px solid $gray-lighter;
                    max-height: 180px;
                    min-height: 110px;
                    left: 0;
                    padding: 0;
                    width: 100% !important;
                    div.field-content {
                      width: 100% !important;
                      img {
                        height: auto;
                        width: 100%;
                      }
                    }
                  }
                }
              }
            }

            &#block-nodeblock-24743 {
              padding: 20px 10px;
              h2 {
                display: none;
              }
              div.content {
                padding: 0;
              }
            }

            div.view.view-anuncios-web {
              div.view-content {
                div.views-row {
                  border-left: 2px solid transparentize($gray-lighter, .5);
                  margin-bottom: 15px;
                  padding-left: 5px;
                  a {
                    &:hover {
                      font-weight: 400;
                      text-decoration: none;
                    }
                  }

                  div.views-field-title {
                    font-size: $font-size-small * 1.1;
                    line-height: 1.2;
                    text-transform: uppercase;
                  }

                  div.field-content {
                    span#_mb_ann_add {
                      display: none;
                    }
                  }

                  a.anuncios-pi-processed {
                    text-transform: uppercase;
                  }

                  div.views-field-type,
                  div.views-field-field-nombre,
                  div.views-field-field-cargo,
                  div.views-field-field-an-texto2 {
                    font-size: $font-size-small;
                    font-weight: 100;
                  }

                  div.views-field-created,
                  div.views-field-field-fecha-publicacion {
                    font-style: italic;
                    font-size: $font-size-small * 0.8;
                  }

                  div.views-field-field-nombre,
                  div.views-field-field-an-texto1 {
                    font-weight: 400;
                  }
                }
              }
              div.more-link {
                text-transform: uppercase;
              }
              h2 {
                display: none;
              }
              div.views-row {
                border: none !important;
                margin: 0 !important;
                padding: 0 !important;
              }
              div.field-content {
                a {
                  display: block;
                  img {
                    height: auto;
                    width: 100%;
                  }
                }
              }
            }

            div.view-empresas-frontal.view-display-id-bloque_losmasvistos,
            div.view.view-empresas,
            div.view.view-noticias,
            div.view-publicaciones,
            div.view-protocolos,
            div.view-directrices-oit,
            div.view-ntps,
            div.view-guias-tecnicas,
            div.view-blogs,
            div.view-carteles-anc,
            div.view-papers,
            div.view.view-editoriales {
              counter-reset: contador-lista-noticias-block;
              div.view-content {
                div.views-row {
                  border-bottom: 1px solid $gray-lighter;
                  counter-increment: contador-lista-noticias-block;
                  padding: 5px 0;
                  position: relative;
                  &:before {
                    color: $brand-danger;
                    content: counter(contador-lista-noticias-block);
                    font-size: $font-size-large;
                    font-weight: bold;
                    left: 90%;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    text-align: center;
                    width: 10%;
                  }
                  &:last-of-type {
                    border-bottom: none;
                  }
                  div {
                    width: 90%;
                  }
                  div.views-field-field-publ-imagen {
                    div.field-content {
                      position: relative;
                      img {
                        height: auto;
                        width: 100%;
                      }
                    }
                  }
                  div.views-field-field-fecha-publicacion {
                    display: none;
                    font-size: $font-size-small;
                    margin-top: 10px;
                    a {
                      &:hover {
                        text-decoration: none;
                      }
                    }
                  }
                }
              }
            }
            div.view-empresas-frontal.view-display-id-bloque_recientes,
            div.view-tablonanuncios,
            div.view.view-advanced-forum-active-topics,
            div.view.view-blogger-recomienda,
            div.view.view-contenido-actualizado-reciente {
              div.view-content {
                div.views-row {
                  border-bottom: 1px solid $gray-light;
                  padding: 5px 0;
                  position: relative;
                  &:last-of-type {
                    border-bottom: none;
                  }
                  div.views-field-field-foto,
                  div.views-field-field-publ-imagen {
                    div.field-content {
                      position: relative;
                      img {
                        height: auto;
                        width: 100%;
                      }
                    }
                  }
                  div.views-field-field-fecha-publicacion {
                    display: none;
                    font-size: $font-size-small;
                    margin-top: 10px;
                    a {
                      &:hover {
                        text-decoration: none;
                      }
                    }
                  }
                }
              }
            }

            div.view.view-agenda {
              div.views-field-field-fechas,
              div.views-field-field-sede-lugar {
                font-size: $font-size-small;
                margin-top: 10px;
              }
            }
            form#views-exposed-form-noticias-page,
            form#views-exposed-form-agenda-listado-eventos-agenda,
            form#views-exposed-form-publicaciones-page,
            form#views-exposed-form-protocolos-page,
            form#views-exposed-form-directrices-oit-page,
            form#views-exposed-form-guias-tecnicas-page,
            form#views-exposed-form-ntps-page,
            form#views-exposed-form-blogs-page,
            form#views-exposed-form-papers-canal-orp-papers,
            form#views-exposed-form-carteles-anc-page,
            form#views-exposed-form-editoriales-page {
              div.views-exposed-form {
                div.views-exposed-widgets {
                  div.views-exposed-widget {
                    float: none;
                    padding: 0 !important;
                    width: 100%;
                    input[type=text],
                    input[type=submit],
                    select {
                      border: 1px solid $gray-lighter;
                      width: 100% !important;
                    }
                    input[type=submit] {
                      background: $brand-highlight;
                      border: none;
                      color: white;
                      display: block;
                      margin: 10px auto 0;
                      min-width: 180px;
                      padding: 10px;
                      text-transform: uppercase;
                      width: 92%;
                    }
                    div {
                      float: none;
                      width: 100%;
                    }
                  }
                  div.form-type-radio {
                    label {
                      font-weight: 300 !important;
                    }
                  }
                  div.views-exposed-widget {
                    margin-bottom: 15px !important;
                  }
                }
              }
            }
          }

          div#block-views-blogs-ficha-autor-blog {
            background: #f3f3f3;
            margin: 10px 0;
            padding: 15px;
            padding-bottom: 0;
            div.content {
              div.view {
                div.view-content {
                  div.views-field-title {
                    font-size: $font-size-large;
                    font-weight: 600;
                  }
                  div.views-field-picture {
                    div.field-content {
                      a {
                        display: block;
                        img {
                          height: auto;
                          max-width: 100%;
                        }
                      }
                    }
                  }
                  div.views-field-field-apellidos {
                    font-size: $font-size-large;
                    font-weight: 600;
                  }
                  div.views-field-field-cargo-1 {
                    font-style: italic;
                  }
                  div.views-field-php-1 {
                    margin-top: 15px;

                    .smallbutton {
                      display: none;
                    }

                    .statistics_counter {
                      margin-bottom: 2px;
                      span {
                        background: white;
                        border-radius: 3px;
                        font-size: $font-size-small;
                        padding: 0 5px;
                      }
                    }

                    a[href*="messages/new"] {
                      border-bottom: 1px solid $gray-light;
                      font-size: $font-size-small;
                      display: block;
                      padding-bottom: 10px;
                      margin-bottom: 10px;
                    }

                    div.item-list {
                      border-bottom: 1px solid $gray-light;
                      margin-bottom: 10px;
                      padding-bottom: 10px;
                      ul {
                        list-style: none;
                        padding: 0;
                        li {
                          margin: 5px 0;
                        }
                      }
                    }

                    div#formwrapper_FormSuscribeBloggerActions {
                      display: block !important;
                      margin-bottom: 15px;
                      padding-bottom: 10px;

                      .messages {
                        margin: 10px;
                      }

                      h2 {
                        background: $gray-lighter;
                        color: $brand-warning;
                        font-size: $font-size-small * 1.1;
                        line-height: 1.8;
                        margin: 0 0 5px;
                        padding: 5px 10px 5px;
                        position: relative;

                        &:first-letter {
                          font-size: $font-size-small;
                        }
                      }

                      div.content {
                        div.user-picture,
                        div.promo-picture {
                          width: 100%;
                          img {
                            width: 100%;
                          }
                        }
                      }

                      div.form-item {
                        clear: both;
                        margin: 5px 4%;
                        width: 92%;
                      }

                      div.form-item.form-type-textfield {
                        @media screen and (max-width: $screen-sm) {
                          display: block;
                        }
                        label, input {
                          display: block;
                        }
                        label {
                          overflow: hidden;
                          width: 0;
                          height: 0;
                          min-width: initial;
                        }

                        input {
                          border: none;
                          box-sizing: border-box;
                          font-weight: 100;
                          padding: 6px 4px;
                          margin: 0 auto;
                          width: 100%;
                        }
                      }

                      div.form-item.form-type-checkbox {
                        text-align: center;
                        input[type=checkbox] {
                          position: relative;
                          top: -3px;
                        }
                        label {
                          font-size: $font-size-small * .8;
                          font-weight: 100;
                          a {
                            font-weight: 400;
                            &:hover {
                              color: $gray-base;
                              text-decoration: none;
                            }
                          }
                        }
                      }

                      input[type=submit] {
                        background: $brand-warning;
                        border: none;
                        color: white;
                        display: block;
                        margin: 10px auto 0;
                        min-width: 180px;
                        padding: 10px;
                        text-transform: uppercase;
                        width: 92%;
                      }
                    }
                  }
                }
              }
            }
          }

          div.BlockUserSubscribe {
            background: #c9f4ff;
            display: block !important;
            margin-bottom: 15px;
            padding-bottom: 10px;

            .messages {
              margin: 10px;
            }

            h1 {
              background: $brand-primary;
              color: white;
              font-size: $font-size-small * 1.1;
              font-weight: 600;
              line-height: 1;
              margin: 0 0 5px;
              padding: 5px 10px 5px;
              position: relative;

              &:first-letter {
                font-size: $font-size-small * 1.3;
              }
            }

            p {
              font-size: $font-size-small;
              padding: 5px 10px 5px;
            }

            div.content {
              div.user-picture,
              div.promo-picture {
                width: 100%;
                img {
                  display: block;
                  margin: 0 auto;
                  max-width: 100%;
                }
              }
            }

            div.form-item {
              clear: both;
              margin: 5px 4%;
              width: 92%;
            }

            div.form-item.form-type-textfield {
              @media screen and (max-width: $screen-sm) {
                display: block;
              }
              label, input {
                display: block;
              }
              label {
                overflow: hidden;
                width: 0;
                height: 0;
                min-width: initial;
              }

              input {
                border: none;
                box-sizing: border-box;
                font-weight: 100;
                padding: 6px 4px;
                margin: 0 auto;
                width: 100%;
              }
            }

            div.form-item.form-type-checkbox {
              text-align: center;
              input[type=checkbox] {
                position: relative;
                top: -3px;
              }
              label {
                font-size: $font-size-small * .8;
                font-weight: 100;
                a {
                  font-weight: 400;
                  &:hover {
                    color: $gray-base;
                    text-decoration: none;
                  }
                }
              }
            }

            input[type=submit] {
              background: $brand-highlight;
              border: none;
              color: white;
              display: block;
              margin: 10px auto 0;
              min-width: 180px;
              padding: 10px;
              text-transform: uppercase;
              width: 92%;
            }

            input#edit-close {
              display: none;
            }
          }

          div.BlockAnadirEmpresa {
            background: none;
            margin: 0 0 10px !important;
            padding: 0 !important;
            div.content {
              background: transparent;
              padding: 0;
              div.registro-empresa {
                a {
                  //box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
                  background: #1d9cd8;
                  color: white;
                  display: block;
                  font-size: x-large;
                  font-weight: bold;
                  margin: 0;
                  padding: 65px 10px;
                  position: relative;
                  text-align: center;
                  transition: background .3s ease-in-out;
                  &:before {
                    background: url('../images/anadirempresa.png');
                    background-position: bottom center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    bottom: 0;
                    content: '';
                    display: block;
                    height: 55px;
                    opacity: 0.5;
                    position: absolute;
                    left: 10px;
                    width: 75px;
                  }
                  &:hover {
                    background: #DFDFDF;
                    color: #1d9cd8;
                  }
                }
              }
            }
          }

          section#block-views-bloque-usuario-datos-usuario {
            div.content {
              div.view-bloque-usuario {
                div.view-content {
                  div.views-row {
                    div.views-field-picture {
                      text-align: center;
                      img {
                        max-width: 50%;
                      }
                    }
                    div.views-field-created,
                    div.views-field-rid {
                      span.views-label {
                        font-weight: 600;
                      }
                    }
                  }
                }
              }
            }
          }

          div.BlockUserActions {
            div.content {
              a {
                @extend .btn;
                @extend .btn-info;
                display: block;
                margin-bottom: 2px;
                width: 100%;

                &:nth-of-type(n+1) {
                  @extend .btn-primary;
                }
              }
            }
          }
        }
      }

      aside#sidebar-first {
        margin-right: 20px !important;
        @media screen and (max-width: $screen-sm) {
          margin: 0 !important;
          width: 100%;
        }
      }
      aside#sidebar-second {
        margin-left: 20px !important;
        @media screen and (max-width: $screen-md) {
          width: 30%;
        }
        @media screen and (max-width: $screen-sm) {
          margin: 0 !important;
          width: 100%;
        }
      }
    }
  }
}

#block-menu-menu-crear-contenido,
#block-masquerade-masquerade {
  div.content {

    ul {
      margin: 0 !important;
      padding: 0 !important;
      list-style: none !important;
      li {
        list-style: none !important;
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }
}

#block-views-noticias-ultimas-3-noticias {
  h2 {
    margin-bottom: 0;
  }
  div.view-noticias {
    div.view-content {
      div.views-row {
        &:before {
          display: none;
        }
        div.views-field-field-foto {
          background: url("../images/logo.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: auto 50px;
          border: 1px solid $gray-lighter;
          max-height: 180px;
          min-height: 110px;
          overflow: hidden;
          left: 0;
          padding: 0;
          width: 100% !important;
          div.field-content {
            width: 100% !important;
            img {
              height: auto;
              width: 100%;
            }
          }
        }
        div.views-field-title {
          margin-top: 5px;
          width: 100% !important;
        }
      }
    }
  }
}