div#banner-home {
  background: $gray-lighter;
  padding: 15px 0;
  @media screen and (max-width: $screen-sm) {
    padding: 0;
  }
  section.block.block-nodeblock {
    & > h2 {
      display: none;
    }
    & > div.content {
      display: flex;
      justify-content: center;
      div.field-name-field-content {
        div.field-item {
          p {
            display: none;
          }
          div.view.view-anuncios-web {
            max-width: 940px;
            width: 100%;

            div.view-content {
              div.field-content {
                align-items: center;
                display: flex;
                justify-content: center;
                a {
                  img {
                    display: block;
                    height: auto;
                    max-width: 940px;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

div.ui-dialog[aria-describedby=block-prevencionintegral-sbs-blck-user-registry] {
  background: #94e8f4;
  border: none;
  border-radius: 0;
  box-shadow: unset;
  height: 100vh !important;
  left: 0 !important;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0;
  position: fixed !important;
  top: 0 !important;
  width: 100vw !important;
  z-index: 999990;

  @media screen and (min-width: $screen-sm) {
    overflow-y: hidden !important;
  }

  div.messages.error {
    @media screen and (min-width: $screen-sm) {
      margin-left: 46% !important;
      width: 34% !important;
    }
  }

  div.ui-dialog-content {
    @media screen and (min-width: $screen-sm) {
      overflow: visible !important;
    }
    div.content {
      @media screen and (min-width: $screen-sm) {
        padding-bottom: 50px !important;
      }
    }
  }

  div.ui-dialog-titlebar {
    background: #1d9cd8;
    background-image: url('../images/logopiletras.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 70px;
    border: none;
    border-radius: 0;
    height: 80px;
    padding: 0;

    @media screen and (min-width: $screen-sm) {
      background-image: none;
      height: 40px;
    }
    @media screen and (max-width: $screen-sm) {
      background-size: auto 40px;
      height: 45px;
    }

    button.ui-dialog-titlebar-close {
      background: white;
      border: none;
      border-radius: 100%;
      color: $gray-darker;
      display: none;
      line-height: 1;
      padding: 0;
      padding-bottom: 2px;
      text-align: center;
      &:hover {
        background: transparent;
        color: white;
      }
      &:before {
        @extend .fa;
        content: '\f00d';
      }
    }
  }

  form[id^=formusersubscribe] {
    @media screen and (max-width: $screen-sm) {
      padding-bottom: 25px;
    }

    @media screen and (min-width: $screen-sm) {
      margin-left: 45%;
      width: 35%;
    }

    & > div {
      div.user-picture {
        clear: both;
      }

      div.user-picture,
      div.promo-picture {
        text-align: center;

        @media screen and (min-width: $screen-sm) {
          background: white;
          height: 5000px;
          left: 20%;
          padding: 10px;
          position: absolute;
          text-align: center;
          top: 0;
          width: 25%;
        }

        &:before {
          background: url("../images/logo_big.png");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          content: '';
          display: block;
          height: 120px;
          margin-bottom: 10px;
          margin-left: auto;
          margin-right: auto;
          margin-top: 20px;
          width: 50%;
          @media screen and (max-width: $screen-sm) {
            display: none;
          }
        }

        img {
          margin: 10px auto;
          max-height: 30vh;
          max-width: 100%;
          width: auto;
          @media screen and (max-width: $screen-xs) {
            height: auto !important;
            margin: 10px auto;
            max-width: 150px;
          }
        }
      }

      div.content {
        align-items: center;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        margin: 0 auto;
        max-width: 100%;

        @media screen and (min-width: $screen-sm) {
          min-height: 180px;
        }

        @media screen and (max-width: $screen-sm) {
          display: block;
        }

        h1 {
          color: #175b7e;
          font-family: $font-family-sans-serif;
          font-size: 20px;
          font-weight: 600;
          margin: 0 auto;
          max-width: 80%;
          text-align: justify;
          @media screen and (max-width: $screen-xs) {
            max-width: unset;
          }
        }

        p {
          font-size: $font-size-base;
          margin: 0 auto;
          max-width: 80%;
          text-align: justify;
          strong {
            font-weight: 600;
          }
          @media screen and (max-width: $screen-xs) {
            max-width: unset;
          }
        }
      }

      div.form-item {
        clear: both;
        margin: 4px auto;
        width: 80%;
      }

      div.form-item.form-type-textfield {
        label, input {
          display: block;
        }
        label {
          overflow: hidden;
          width: 0;
          height: 0;
          min-width: initial;
        }

        input {
          background: white;
          border: none;
          color: $gray-darker;
          flex-grow: 1;
          font-weight: 100;
          margin-left: auto;
          margin-right: auto;
          max-width: 550px;
          padding: 10px;
          width: 75%;
          @media screen and (max-width: $screen-sm) {
            width: 100%;
          }
        }
      }
      div.form-item.form-type-checkbox {
        text-align: center;
        input[type=checkbox] {
          position: relative;
          top: -3px;
        }
        label {
          font-size: small;
          font-weight: 100;
          a {
            font-weight: 400;
            &:hover {
              color: $gray-base;
              font-weight: 600;
              text-decoration: none;
            }
          }
        }
      }
      input[type=submit] {
        background: #2fd0f9;
        border: none;
        color: white;
        display: block;
        margin: 0 auto;
        min-width: 180px;
        padding: 10px;
        text-transform: uppercase;
        width: 50%;
        &[id^=edit-close] {
          background: lighten(#2fd0f9, 10%);
          font-size: $font-size-small;
          margin: 20px auto;
          padding: 2px 10px;
        }
      }
    }
  }
}