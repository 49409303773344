/**
* Contenidos generales
*/
body.page-node:not(.front) {
  div#page-wrapper {
    main#wrapper {
      background: white;

      div#block-system-main {
        padding: 0;
        margin-left: 0 !important;

        @media screen and (max-width: $screen-sm) {
          a,
          p,
          span,
          div {
            hyphens: auto;
            overflow-wrap: break-word;
            word-wrap: break-word;
          }
        }

        img {
          height: auto !important;
          max-width: 100% !important;
        }

        div.custom_message {
          @extend .alert;
          @extend .alert-warning;
        }

        div.content {
          div.field-name-title {
            h2 {
              margin: 10px 0;
            }
          }
        }

        div.field-name-field-video {
          margin-top: 10px;

          iframe {
            width: 100% !important;
          }
        }

        div.field-name-field-term-empresa {
          font-size: larger !important;
          font-weight: bold;
          margin: 15px 0;
        }

        div.field-name-field-content {
          padding: 0 0 15px;

          table {
            margin: 0 0 10px;
            width: 100%;
            td {
              padding: $table-cell-padding;
            }
          }
        }

        div.field-name-field-galeria {
          background: $gray-lighter;
          margin: 10px 0;
          padding: 15px;

          div.field-items {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            position: relative;
            width: 100%;

            div.field-item {
              display: flex;
              flex-flow: row wrap;
              flex-grow: 1;
              flex-shrink: 0;
              justify-content: center;
              margin: 5px 5px;
              max-width: 50%;
              position: relative;
              overflow: hidden;
              width: 24%;
            }
          }
        }

        div.field-name-pi-nodo-extras {
          border-top: 1px solid $gray-lighter;
          margin: 10px 0;
          padding: 15px 0;

          div.sabentis-block-node-extras {
            align-items: center;
            display: flex;
            flex-flow: row wrap;

            @media screen and (max-width: $screen-sm) {
              display: block;
            }

            div.service-links {
              flex-shrink: 0;
              flex-grow: 0;
              width: 60%;

              @media screen and (max-width: $screen-sm) {
                width: 100%;
              }

              ul {
                align-items: flex-end;
                display: flex;
                flex-flow: row wrap;
                justify-content: space-around;
                padding: 0;

                li {
                  flex-grow: 1;
                  flex-shrink: 1;
                  display: flex;
                  justify-content: center;

                  @media screen and (max-width: $screen-sm) {
                    width: 30%;
                  }
                }
              }
            }

            div.comments {
              align-items: center;
              background: #fdf1b6;
              display: flex;
              flex-flow: column wrap;
              flex-grow: 0;
              flex-shrink: 0;
              justify-content: center;
              padding: 10px;
              width: 40%;

              @media screen and (max-width: $screen-sm) {
                width: 100%;
              }

              b {
                display: block;
              }
            }
          }
        }

        div.view.view-contenido-relacionado {
          clear: both;
          float: none;
          padding-bottom: 15px;
          padding-top: 15px;

          div.view-header {
            h2 {
              background: lighten($gray-lighter, 10%);
              font-size: $font-size-h3;
              padding: 10px;
              @media screen and (max-width: $screen-sm) {
                font-size: $font-size-large;
              }
            }
          }

          div.view-content {
            @include global-container;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            padding: 0;
            position: relative;
            width: 100%;

            div.views-row {
              @include front-view-row;
              background: lighten($gray-lighter, 10%);
              flex-grow: 1;
              flex-shrink: 0;
              padding-bottom: 15px;
              margin: 5px 3px;
              position: relative;
              width: 32%;

              div.views-field-type {
                color: white;
                display: block;
                font-size: $font-size-small;
                font-weight: 100;
                min-width: 120px;
                margin: 0;
                padding: 2px 10px;
                position: absolute;
                text-align: center;
                top: 0;
                right: 0;
                max-width: 50%;
                z-index: 1;

                &.tipo-Noticia {
                  background: #ff0066;
                }

                &.tipo-Blog {
                  background: #ff6600;
                }
              }

              div.views-field-title {
                h4 {
                  margin: 0;
                }
              }

              div.views-field-field-foto,
              div.views-field-field-publ-imagen {
                background: url("../images/logo.png");
                background-position: center;
                background-repeat: no-repeat;
                background-size: auto 50px;
                margin-top: 0 !important;
                text-align: center;
                width: 100%;

                a,
                div.field-content {
                  align-items: center;
                  display: flex;
                  height: 120px;
                  justify-content: center;
                  overflow: hidden;
                  position: relative;
                  width: 100%;

                  img {
                    display: block;
                    height: auto;
                    min-height: 50px;
                    width: 100%;
                  }
                }

                & > img {
                  display: block;
                  height: auto;
                  min-height: 50px;
                  width: 100%;
                }

                &.has-video-embed {
                  a {
                    @extend .fa-play;

                    &:before {
                      @extend .fa;
                      align-items: center;
                      border: 10px solid $brand-highlight;
                      border-radius: 100%;
                      color: $brand-highlight;
                      display: flex;
                      font-size: $font-size-h1;
                      height: 80px;
                      justify-content: center;
                      left: 50%;
                      opacity: 0.75;
                      position: absolute;
                      top: 50%;
                      transform: translateX(-50%) translateY(-50%);
                      width: 80px;
                    }
                  }
                }
              }
            }
          }
        }
        div.view.view-papers {
          div.view-header {
            p, h2 {
              background: lighten($gray-lighter, 10%);
              font-size: $font-size-h3;
              padding: 10px;
              @media screen and (max-width: $screen-sm) {
                font-size: $font-size-large;
              }
            }
          }
          div.view-content {
            @include global-container;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            padding: 0;
            position: relative;
            width: 100%;

            div.views-row {
              @include front-view-row;
              background: lighten($gray-lighter, 10%);
              flex-grow: 1;
              flex-shrink: 0;
              padding-bottom: 10px;
              margin: 5px 3px;
              position: relative;
              width: 30%;

              @media screen and (max-width: $screen-sm) {
                height: auto;
                margin: 10px 2px !important;
                max-width: 100%;
                width: 49%;
              }

              @media screen and (max-width: $screen-xs) {
                margin: 10px 5px;
                width: 100%;
              }

              &:before {
                background: red;
                content: 'Papers';
              }

              & > div.views-field {
                float: right;
                width: 65%;
              }

              & > div.views-field-field-organizaciones,
              & > div.views-field-field-tema {
                padding: 0 10px;
                margin-left: 0;
                margin-right: 0;
              }

              & > div.views-field-field-tema {
                width: 63%;
              }

              & > div.views-field-field-foto-paper-autor {
                background-size: auto 30px;
                float: left;
                position: relative;
                margin: 5px 5px 5px 0;
                width: 30%;

                div.field-content {
                  height: auto;

                  a {
                    height: auto;

                    img {
                      max-height: 235px;
                      max-width: 100%;
                      height: auto;
                      width: auto;
                    }
                  }
                }
              }

              & > div.views-field-picture {
                text-align: center;

                div.field-content {
                  img {
                    max-width: 100%;
                  }
                }
              }

              div.views-field-title {
                clear: both;
                margin-top: 27px;
                width: 100%;
              }

              div.views-field-field-foto-paper-autor {
                text-align: center;
                padding-left: 10px;
              }

              div.views-field-field-autor {
                font-size: $font-size-small;
                div.field-name-field-foto-paper-autor {
                  display: none;
                }

                div.field-name-field-nombre,
                div.field-name-field-apellidos {
                  display: inline-block;
                  padding-right: 5px;
                  font-weight: 600;
                }

                div.field-name-field-empresa {
                  font-style: italic;
                }
              }

              div.views-field-field-congreso {
                color: red;
                display: block;
                font-size: $font-family-base;
                font-weight: 700;
                max-width: 35%;
                min-width: 120px;
                padding: 2px 10px;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
              }

              div.views-field-field-keywords {
                clear: both;
                font-size: $font-size-small;
                padding: 0 10px;
                width: 100%;
              }
            }
          }
        }

        div.view.view-blogs.view-display-id-entity_view_1 {
          div.views-row {
            div.blogger-header {
              border-left: 2px solid $gray-dark;
              padding-left: 5px;
              margin: 0 0 10px;

              a {
                color: $gray-darker;
                &:hover,
                &:active,
                &:focus {
                  color: $gray-darker;
                }
              }

              span.title {
                font-size: $font-size-h3;
                font-weight: 600;

                @media screen and (max-width: $screen-sm) {
                  font-size: $font-size-large;
                }
              }

              span.nombre {
                font-size: $font-size-base;
              }

              a.username {
                font-size: $font-size-base;
              }

              span.url {
                display: none;
                font-size: $font-size-small;
              }
            }

            div.views-field-field-cabecera {
              div.field-content {
                a {
                  display: block;

                  img {
                    height: auto;
                    width: 100%;
                  }
                }
              }
            }
          }
        }

        div.field-name-field-adjuntos {
          @include clearfix;
          background: #f3f3f3;
          display: flex;
          flex-flow: row nowrap;
          margin: 10px 0;
          padding: 10px;
          position: relative;

          &:before {
            background: url("../images/documents.svg");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            content: '';
            display: block;
            flex-grow: 0;
            flex-shrink: 0;
            height: 45px;
            position: relative;
            width: 45px;
          }

          div.field-items {
            display: flex;
            flex-flow: column nowrap;
            flex-shrink: 1;
            justify-content: center;
            padding-left: 5px;
            width: 98%;

            a {
              word-break: break-all;
            }
          }
        }

        div.field-name-field-enlaces {
          @include clearfix;
          background: #f3f3f3;
          display: flex;
          flex-flow: row nowrap;
          margin: 10px 0;
          padding: 10px;
          position: relative;

          &:before {
            background: url("../images/cursorhand.svg");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            content: '';
            display: block;
            flex-grow: 0;
            flex-shrink: 0;
            height: 45px;
            position: relative;
            width: 45px;
          }

          div.field-items {
            display: flex;
            flex-flow: column nowrap;
            flex-shrink: 1;
            justify-content: center;
            padding-left: 5px;
            width: 98%;

            div.field-item {
              a {
                &:before {
                  content: ">";
                  padding-right: 5px;
                }
              }
            }
          }
        }

        div.field-name-title {
          h2 {
            font-size: $font-size-h1;
            font-weight: 600;

            @media screen and (max-width: $screen-sm) {
              font-size: $font-size-large;
            }
          }
        }

        div.field-name-field-entradilla {
          font-style: italic;
          font-size: $font-size-large * 0.8;
          margin-bottom: 15px;
        }

        div.field-name-field-fecha-publicacion {
          display: inline-block;
          font-size: $font-size-small;
          margin-right: 10px;
        }

        div.field-name-field-fechas {
          font-style: italic;
        }

        div.field-name-field-sede-lugar {
          font-weight: 600;
          margin-bottom: 10px;
        }

        div.field-name-field-localizacion {
          background: $brand-primary;
          display: block;
          a {
            color: white;
            font-weight: 500;
            &:hover {
              color: $brand-info;
            }
          }
          div.vcard {
            color: white;
            padding: 10px;
          }
          div.gmap-map {
            margin: 0 auto;
            max-width: 100%;
            //width: 100% !important;
          }
        }

        div.view.view-tagsnodo {
          border-left: 2px solid $gray-light;
          font-size: $font-size-base * 0.9;
          display: inline-block;
          padding: 0 5px;

          div.view-content {
            strong {
              display: none;
            }

            a {
              &:after {
                content: ',';
                margin-right: 2px;
              }

              &:last-of-type {
                &:after {
                  display: none;
                }
              }
            }
          }
        }

        div.field-name-field-foto {
          margin: 10px 0 30px;
          div.field-item {
            img {
              background-color: white;
              min-height: 300px !important;
              width: auto !important;
            }
          }
        }

        div.anuncios-within,
        a.anuncios-within {
          border-top: 2px solid $brand-danger;
          border-bottom: 1px solid $brand-danger;
          display: block;
          margin-bottom: 15px;
          margin-top: 10px;
          padding: 10px 0;
          text-align: center;

          &.add-banner {
            border: none !important;
            img {
              height: auto;
              max-width: 100%;
              width: auto;
              &.apaisado {
                @media screen and (max-width: $screen-sm) {
                  display: none;
                }
              }
              &.estrecha {
                @media screen and (min-width: $screen-sm) {
                  display: none;
                }
              }
            }
          }

          p {
            margin-bottom: 0;
          }

          span {
            background-color: #EFC439;
            border-radius: 2px;
            color: #fff;
            font-size: 11px;
            padding: 0 2px;
            line-height: 14px;
            vertical-align: baseline;
          }

          p.enlace {
            color: #3400DC;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 500;
            text-decoration: none;
          }

          p.texto1 {
            color: $gray-light;
            display: inline-block;
            font-weight: 600;
            font-family: Arial, Helvetica, sans-serif;
          }

          p.texto2 {
            color: $gray-light;
            display: inline-block;
            font-family: Arial, Helvetica, sans-serif;
          }
        }

      }
    }
  }
}

/**
* Contenidos de papers
*/
body.node-type-paper.seccion-paper,
body.node-type-paper.seccion-papers {
  div#main-content {
    h1#page-title {
      display: block !important;
    }

    article.node-paper {

      fieldset#paper-ayuda {
        height: 32px;
        div.fieldset-wrapper {
          margin-bottom: 0;
        }
      }

      div.field-name-field-aprobacion-papers {
        @extend .alert;
        @extend .alert-warning;
        margin-top: 5px;
        margin-bottom: 5px;
      }

      div.field-name-field-congreso {
        border-bottom: 1px solid $gray-lighter;
        border-top: 1px solid $gray-lighter;
        font-size: $font-size-large;
        font-weight: 600;
        padding: 10px 0;
        margin: 15px 0;
      }

      div.field-name-field-version-pdf {
        margin: 10px 0;
      }

      div.field-name-field-abstract {
        background: $brand-info;
        margin: 10px 0;
        padding: 15px;
      }

      div.view.view-tagsnodo {
        font-size: $font-size-large !important;
        margin: 10px 0;
      }

      div.field-collection-container {
        border: none;
      }

      div.field-name-field-coautores {
        div.field-collection-view {
          padding-top: 0;
        }
      }

      div.field-name-field-autor {
        div.field-item {
          margin-bottom: 0;
          padding-bottom: 0;
          padding-top: 0;
        }
      }

      div.field-name-field-autor,
      div.field-name-field-coautores {
        @include clearfix;
        background: $brand-info;
        margin: 10px 0;
        padding: 15px;

        div.field-name-field-nombre,
        div.field-name-field-apellidos {
          display: inline-block;
          font-weight: 600;

          &:after {
            content: ' ';
          }
        }

        div.field-name-field-empresa {
          font-style: italic;
        }

        div.field-name-field-foto-paper-autor {
          float: left;
          margin: 0 10px 10px 0;
        }
      }
    }
  }
}

/**
* Nodo tipo publicacion
*/
body.node-type-publicacion {
  div#block-system-main {
    div.group-columnas {
      display: flex;
      flex-flow: row nowrap;
      & > div {
        float: none;
      }
    }
    div.view-tagsnodo {
      clear: both;
      margin-bottom: 10px !important;
      width: 100%;
    }
    div.field-name-field-publ-imagen {
      float: left;
      width: 50%;
      @media screen and (max-width: $screen-sm) {
        float: none;
        width: 100%;
      }
    }
    div.group-publ-datos-biblio {
      float: left;
      padding: 10px;
      width: 50%;
      @media screen and (max-width: $screen-sm) {
        float: none;
        padding: 0;
        width: 100%;
      }
    }
    div.field-name-field-publicacion-adjunto {
      clear: both;
      padding-top: 15px;
      width: 100%;
    }
  }
}

/**
* Nodo de empresa
*/
body.node-type-empresa.seccion-empresas {
  div#empresas-destacadas-wrapper {
    margin-left: 0;
    margin-right: 0;
    @media screen and (max-width: $screen-sm) {
      display: none;
    }
  }
  div#page-wrapper {
    main#wrapper {
      div#block-system-main {
        div.content {
          margin-top: 15px;
          div#ficha-header {
            display: flex;
            padding: 10px;

            @media screen and (max-width: $screen-sm) {
              display: block;
            }

            div.field-name-field-logo {
              align-items: center;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 30%;

              @media screen and (max-width: $screen-sm) {
                width: 100%;
              }

              img {
                max-height: 80px;
                width: auto;
              }
            }
            div.ficha-header-title {
              align-items: center;
              display: flex;
              width: 70%;

              @media screen and (max-width: $screen-sm) {
                width: 100%;
              }

              h1 {
                margin: 0;
                padding: 0;
                @media screen and (max-width: $screen-sm) {
                  font-size: $font-size-large;
                }
              }
            }
          }

          div.empresa-tabpanel {
            border: none;
            border-radius: 0;
            ul.ui-tabs-nav {
              background: lighten($gray-lighter, 10%);
              border: none;
              border-radius: 0;
              font-size: $font-size-base;
              margin: 0;
              li {
                background: none;
                border: none;
                border-radius: 0;
                &.ui-tabs-active {
                  border-bottom: 2px solid $brand-primary;
                }
              }
            }
          }

          div.field {
            font-size: $font-size-base;
          }

          div.field-label {
            font-weight: 600;
            text-transform: uppercase;
          }

          div.field-name-field-entradilla {
            font-size: $font-size-large * 0.8;
          }

          div.field-name-field-galeria {
            div.field-label {
              display: none;
            }
          }
          div.field-name-field-localizacion {
            margin: 0 auto;
            max-width: 800px;
            div.field-label {
              color: white;
              padding: 10px 10px 0;
            }

            div.field-item {
              padding-top: 0;
            }
          }

          div.field-name-field-enlaces {
            background: none;
            display: block;
            &:before {
              display: none;
            }
            div.field-label {
              margin-bottom: 10px;
            }
            div.field-items {
              width: 100%;
              div.field-item {
                a {
                  color: $link-color;
                  &:before {
                    display: none;
                  }
                }
              }
            }
          }

          form#formcontactoempresa {
            input[type=submit] {
              @include btn;
              @include btn-primary;
            }
            div.form-item {
              label {
                display: block;
                font-size: $font-size-base;
                font-weight: 600;
                width: 100%;
              }
              input[type=text] {
                width: 100%;
              }
              textarea {
                width: 100%;
              }
            }
          }

          div.view-empresas-premium {
            div.view-content {
              div.views-row {
                font-size: $font-size-base;
                margin-bottom: 15px;
                div.views-field-title {
                  font-weight: 600;
                }
                div.views-field-created {
                  font-size: $font-size-small;
                }
              }
            }
            div.view-footer {
              a {
                @include btn;
                @include btn-primary;
              }
            }
          }
        }
      }
    }
  }
}

/**
* Congresos
*/

.cabecera-congreso {
  width: 100%;
}

body.p0-congresos {
  .content {
    padding: 0 15px 15px;
    div.alert {
      margin-bottom: 10px;
    }
  }

  div.tabs {
    display: flex;
    @media screen and (min-width: $screen-md) {
      justify-content: center !important;
    }

    .tabs.primary {
      li {
        height: auto;
        a {
          @media screen and (min-width: $screen-md) {
            font-size: 26px !important;
          }
        }
      }
    }
  }

  div.form-type-checkboxes {
    .form-item {
      display: inline-block;
    }
  }
  form {
    div {
      max-width: 100%;
    }
    div.form-type-textfield,
    div.form-item-pass,
    div.form-item-mail,
    div.form-type-select {
      label {
        display: block;
        width: 100%;
      }
      input, textarea, select {
        background: #eeeeee;
        border: #ddd;
        border: none;
        font-weight: 100;
        padding: 10px;
        text-align: left;
        width: 50%;
        @media screen and (max-width: $screen-sm) {
          text-align: center;
          width: 100%;
        }
      }
    }
    div.form-type-checkbox {
      label {
        position: relative;
        top: 3px;
      }
    }
    div#edit-field-coautores {
      div.form-type-textfield,
      div.form-item-pass,
      div.form-item-mail,
      div.form-type-select {
        select {
          background: white;
        }
        input {
          background: white;
          width: 100%;
        }
      }

      tr.even, tr.odd {
        background: #F0F0F0;
        td {
          padding: 5px;
        }
      }

      table {
        width: 100%;
        td.field-multiple-drag {
          text-align: center;
        }
      }

      a.tabledrag-handle {
        padding: 10px;
        div.handle {
          background-position: center;
          height: 35px;
          width: 35px;
        }
      }
    }
    div#edit-field-coautores,
    table {
      position: relative;
      top: 0;
      left: 0;
      overflow-x: scroll;
    }
    input.form-submit {
      @include btn;
      @include btn-primary;
    }
  }
}

/**
* Comentarios
*/
section#comments {
  background: #f3f3f3;
  padding: 10px;

  h2 {
    font-size: $font-size-large;
    font-weight: bold;
    padding: 0;
    margin: 10px 15px 10px;
  }

  section#comment-form-wrapper {
    padding: 10px 0;
    margin-top: 10px;

    h2 {
      margin: 0;
    }

    form#comment-form {
      @include clearfix;
      padding: 0;

      div.form-item {
        margin: 10px 0;

        label {
          display: block;
          font-size: $font-size-small;
          font-weight: 400;
          margin-bottom: 5px;
        }

        &.form-type-checkbox {
          label {
            display: inline-block;
            position: relative;
            top: 3px;
          }
        }
      }

      input#edit-submit {
        @include btn;
        @include btn-primary;
        @extend .btn-block;
      }
    }
  }

  article {
    background: white;
    border-bottom: 1px solid lighten($gray-lighter, 5%);
    padding: 10px;

    &:last-of-type {
      border-bottom: none;
    }

    header {
      @include clearfix;

      div.user-picture {
        float: left;
        margin-right: 5px;

        a {
          display: block;

          img {
            height: auto;
            width: 35px;
          }
        }
      }

      h3 {
        font-size: $font-size-large;
        margin: 0;
      }

      p.submitted {
        font-size: $font-size-small;
      }
    }

    footer {
      font-size: $font-size-small;

      a {
        text-transform: uppercase;
      }
    }
  }
}

.node-unpublished {
  background: none !important;
}

body.page-anuncio {
  div#block-system-main {
    padding: 10px 15px 0;
  }
}