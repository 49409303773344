html body.admin-menu {
  @media screen and (max-width: $screen-sm) {
    margin-top: 0 !important;
  }
}

body {
  a#admin-launcher {
    display: none;
  }
}

html body.admin-menu,
html body.page-admin {
  a#admin-launcher {

    &:hover,
    &:focus {
      text-decoration: none;
    }

    @media screen and (max-width: $screen-sm) {
      align-items: flex-end;
      background: black;
      border-top-right-radius: 90px;
      bottom: 0;
      color: white;
      display: flex;
      font-size: 0;
      justify-content: flex-start;
      height: 80px;
      left: 0;
      position: fixed;
      transition: all .8s ease-in-out;
      width: 80px;
      z-index: 1000000;
      &:before {
        font-size: $font-size-h1;
        padding: 15px;
      }
    }

    @extend .fa-close;
    &:before {
      @extend .fa;
    }
    &.collapsed {
      @extend .fa-cog;
    }
  }

  div#admin-menu {

    %collapse {
      @extend .collapse;
    }

    @media screen and (min-width: $screen-sm){
      height: auto !important;
      display: block !important;
    }
    @media screen and (max-width: $screen-sm) {
      @extend %collapse;
      height: 0;
      overflow: hidden;

      &.in {
        height: auto;
        overflow: visible;
      }

      // Cambiamos el posicionamiento de los menues dropdown.
      .dropdown {
        position: absolute;
      }
    }

    div#admin-menu-wrapper {

      @media screen and (max-width: $screen-sm) {
        display: flex;
        flex-flow: column nowrap;
        padding: 0;
        overflow: visible;

        a.shortcut-toggle {
          position: absolute;
          right: 10px;
          top: 10px;
          z-index: 6000;
        }

        & > ul {
          position: relative;
          left: 0;
          top: 0;
          width: 100%;
          li {
            li {
              filter: none !important;
              opacity: 1 !important;
            }
          }
          &:hover {
            z-index: 5000;
          }
        }
      }

      ul#admin-menu-icon {
        @media screen and (max-width: $screen-sm) {
          position: relative;
          background: $gray-light;
          padding: 2px 10px;
          width: 100%;
          li {
            a {
              &.active-trail {
                background: transparentize(#444, 0.5);
                border-radius: 0;
              }
            }
          }
        }
      }
      ul#admin-menu-menu {
        @media screen and (max-width: $screen-sm) {
          display: flex;
          position: relative;
          flex-flow: column nowrap;
        }
      }
      ul#admin-menu-icon,
      ul#admin-menu-menu {
        @media screen and (max-width: $screen-sm) {
          & > li {
            position: relative;
            &:hover {
              z-index: 1000;
            }
            & > a {
              padding: 10px 10px;
              &:hover {
                border-radius: 0 !important;
              }
            }
            & > ul {
              left: 0;
              width: auto;
              z-index: 1000 !important;
              li {
              }
            }
          }
        }
      }

      ul#admin-menu-account,
      ul#admin-menu-users,
      ul#admin-menu-search {
        @media screen and (max-width: $screen-sm) {
          background: $gray-darker;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-around;
          padding: 5px 0;
        }
      }

      ul#admin-menu-account {
        @media screen and (max-width: $screen-sm) {
          //%btn-menu {
          //  @extend .btn;
          //}
          //%btn-menu-primary {
          //  @extend .btn-primary;
          //}
          //%btn-menu-danger {
          //  @extend .btn-danger;
          //}
          li {
            float: none;
            a {
              //@extend %btn-menu;
              border-radius: 0;
              font-size: $font-size-small;
              text-transform: uppercase;
              &[href*=logout] {
                //@extend %btn-menu-danger;
              }
              &[href*=usuarios] {
                //@extend %btn-menu-primary;
              }
            }
          }
        }
      }
      ul#admin-menu-users {
        background: inherit;
      }
    }
  }
}