/**
* Estilos globales de página.
*/
body {
  div#page-wrapper {
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100vh;

    div#main-wrapper {
      @include global-container;

      div#main {
        div#content-outer {
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;

          @media screen and (max-width: $screen-sm) {
            display: block;
          }

          div#main-content {
            background: transparentize(white, .10);
            margin: 20px 0;
            position: relative;
            width: 56%;

            @media screen and (max-width: $screen-md) {
              width: 70%;
            }

            @media screen and (max-width: $screen-sm) {
              margin: 10px 0 0;
              width: 100%;
            }

            a.feed-icon {
              bottom: 0;
              left: 0;
              position: absolute;
            }
            // region Breadcrumb
            div#breadcrumb {

              @media screen and (max-width: $screen-md) {
                display: none;
              }

              background: lighten($gray-base, 10%);
              display: flex;
              flex-flow: row wrap;
              padding: 0;
              margin: 0;
              font-size: 0;

              a {
                background: $brand-primary;
                color: white;
                display: block;
                font-size: $font-size-small;
                padding: 8px 8px 8px 16px;
                position: relative;
                text-align: center;
                text-decoration: none;

                &:after {
                  border-bottom: 16px solid transparent;
                  border-left: 10px solid $brand-primary;
                  border-top: 16px solid transparent;
                  content: '';
                  display: block;
                  height: 0;
                  left: 100%;
                  position: absolute;
                  top: 0;
                  width: 0;
                  z-index: 1;
                }

                &:hover {
                  background: lighten($brand-primary, 60%) !important;
                  color: $brand-primary;
                  text-decoration: none;

                  &:after {
                    border-left-color: lighten($brand-primary, 60%) !important;
                  }
                }
              }

              @for $i from 0 through 9 {
                a {
                  &:nth-of-type(#{$i + 1}) {
                    background: lighten($brand-primary, $i * 3);

                    &:after {
                      border-left-color: lighten($brand-primary, $i * 3);
                    }
                  }
                }
              }
            }
            // endregion
            h1#page-title {
              display: none;
            }
            // region Tabs
            ul.action-links,
            div.tabs,
            ul.tabs {
              border: none !important;
              display: flex;
              flex-flow: row wrap;
              justify-content: flex-start;
              padding-left: floor(($grid-gutter-width / 2));
              padding-right: ceil(($grid-gutter-width / 2));

              li {
                border: none !important;
                display: block;
                flex-grow: 0;
                flex-shrink: 0;
                font-size: $font-size-small;

                &.active {
                  background: $brand-primary;

                  a {
                    color: white;
                  }
                }

                a {
                  background: transparent;
                  border: none;
                  display: block;
                  margin: 0;
                  padding: 5px 10px;
                  text-align: center;
                }
              }
            }

            div.tabs {
              background: rgb(255, 255, 255);
              padding: 0;
            }

            ul.action-links {
              background: rgb(240, 240, 240) !important;
              border-top: 1px dotted $gray-light !important;

              li {
                border: none !important;

                a {
                  color: lighten($brand-danger, 10%);
                  background: transparent;
                  text-decoration: none;

                  &:hover {
                    background: lighten($brand-danger, 15%);
                    color: white;
                  }
                }
              }
            }
            // endregion
            // region Contenidos
            div.region.region-content {
              div#block-system-main {
              }
            }
            // endregion
          }
        }
      }
    }
  }
}

/**
* Página de login.
*/
body.page-user-login,
body.page-user-register,
body.page-toboggan,
body.page-user {
  div#main-content {
    background: transparent !important;
  }

  div#block-system-main {
    background: #cececa;
    div#login-message {
      @extend .alert;
      @extend .alert-warning;
      color: #db5c5c;
      font-size: $font-size-base;
      margin: 0 auto 15px;
      text-align: left;
    }
  }
}

div#login-links,
.toboggan-unified #login-links a.lt-active,
.toboggan-unified #login-links {
  display: flex;
  flex-flow: row wrap;
  margin-top: 15px;
  padding: 0 10px;

  a,
  a.lt-active,
  a.lt-active:hover {
    @include btn;
    @include btn-primary;
    border: none;
    display: block;
    flex-grow: 1;
    margin: 10px !important;
    padding-bottom: 15px;
    padding-top: 15px;
    min-width: 180px;
    width: 30%;

    @media screen and (max-width: $screen-sm) {
      width: 100%;
    }
  }
}

div#login-form,
div#register-form,
div#pass-form {
  padding: 0 20px 10px;

  div.form-item {
    clear: both;
    margin: 4px 0;
  }

  div.form-item.form-type-textfield,
  div.form-item.form-type-password {
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    width: 100%;

    @media screen and (max-width: $screen-sm) {
      display: block;
    }

    label, input {
      display: block;
    }

    label {
      font-weight: 600;
    }
    div.description {
      font-weight: 100;
    }

    div.password-strength {
      max-width: 450px;
      width: 80%;
    }

    div.description {
      font-size: $font-size-small;
    }

    input {
      //background: transparentize($gray-base, 0.1);
      border: none;
      //color: white;
      font-weight: 100;
      padding: 10px;
      text-align: left;
      width: 50%;

      @media screen and (max-width: $screen-sm) {
        text-align: center;
        width: 100%;
      }
    }
  }

  div.form-item.form-type-textfield {
    margin-top: 5px;
  }

  div#edit-account {
    margin-bottom: 20px;
  }

  fieldset {
    margin-top: 25px;

    legend {
      margin-bottom: 5px;
    }
  }

  input.form-submit {
    @include btn;
    @include btn-primary;
    border: none;
    color: white;
    display: block;
    margin: 0;
    min-width: 180px;
    padding: 8px 10px;
    text-transform: uppercase;
    width: 50%;
    @media screen and (max-width: $screen-sm) {
      margin: 0 auto;
    }
  }
}

/**
* Estilos de cajita.
*/
@mixin front-view-row {
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
  flex-shrink: 0;
  margin: 5px 5px;
  max-width: 50%;
  position: relative;
  overflow: hidden;
  width: 24%;

  @media screen and (max-width: $screen-md) {
    width: 33%;
  }

  @media screen and (max-width: $screen-sm) {
    height: auto;
    margin: 10px 2px !important;
    max-width: 100%;
    width: 49%;
  }

  @media screen and (max-width: $screen-xs) {
    margin: 10px 5px;
    width: 100%;
  }

  &:before,
  div.tipo-publicacion {
    color: white;
    display: block;
    font-size: $font-size-small;
    font-weight: 100;
    min-width: 120px;
    padding: 2px 10px;
    position: absolute;
    text-align: center;
    top: 0;
    right: 0;
    z-index: 1;
    a {
      color: white;
    }
  }

  a {
    color: $gray-base;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
  }

  div.views-field {
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
    margin-top: 5px;
    width: 100%;
  }

  div.views-field-field-foto,
  div.views-field-blog-header-alias,
  div.views-field-field-publ-imagen {
    background: url("../images/logo.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 50px;
    margin-top: 0 !important;
    min-height: 80px;
    text-align: center;
    width: 100%;

    a,
    .field-content {
      align-items: center;
      display: flex;
      height: 160px;
      justify-content: center;
      overflow: hidden;
      position: relative;
      width: 100%;

      img {
        display: block;
        height: auto;
        min-height: 50px;
        width: 100%;
      }
    }

    & > img {
      display: block;
      height: auto;
      min-height: 50px;
      width: 100%;
    }

    &.has-video-embed,
    .has-video-embed {
      a {
        &:before {
          @extend .fa;
          align-items: center;
          border: 10px solid $brand-highlight;
          border-radius: 100%;
          content: '\f04b';
          color: $brand-highlight;
          display: flex;
          font-size: $font-size-h1;
          height: 80px;
          justify-content: center;
          left: 50%;
          opacity: 0.75;
          position: absolute;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 80px;
        }
      }
    }
  }

  div.views-field-title {
    padding: 0 10px;

    a {
      color: $brand-primary;
      font-size: 16px;
      font-weight: 500;
      text-decoration: none;
    }
  }

  div.views-field-field-sector,
  div.views-field-field-tema {
    align-self: flex-end;
    /*border-left: 1px solid $gray-light;*/
    margin-left: 5px;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-top: 10px;
    width: 96%;
    z-index: 10;

    a {
      color: $brand-primary;
      font-size: $font-size-small;
      padding-left: 5px;
    }
  }

  div.views-field-field-fechas,
  span.date-display-single,
  div.views-field-created {
    display: block;
    font-size: $font-size-small;
    font-style: italic;
    padding: 0 10px;
  }

  div.views-field-field-fechas span.date-display-single {
    padding: 0 !important;
  }

  div.views-field-totalcount {
    font-size: $font-size-base;
    padding: 0 10px;
  }

  div.views-field-field-sede-lugar {
    font-size: $font-size-base;
    padding: 0 10px;
  }

  div.views-field-field-entradilla {
    font-size: $font-size-base;
    padding: 0 10px;
    max-height: 150px;
  }

  div.views-field-field-apellidos,
  div.views-field-field-nombre,
  div.views-field-field-publ-autores,
  div.views-field-field-publ-editorial,
  div.views-field-field-term-empresa {
    font-weight: 600;
    font-size: $font-size-base;
    margin-top: 2px;
    padding: 0 10px;
  }

  div.views-field-field-cargo {
    font-weight: 100;
    font-size: $font-size-base;
    padding: 0 10px;
  }

  div.views-field-field-fecha-publicacion,
  div.views-field-field-publ-year {
    font-size: $font-size-small;
    font-style: italic;
    margin-top: 2px;
    padding: 0 10px;
    span.date-display-single {
      padding: 0;
    }
  }
}

/**
* Estilos para view de anuncios, banner-frontal.
*/
div.view.view-anuncios-web,
div.view-anuncios-banner-frontal {
  width: 100%;

  &.view-display-id-embed_responsivo.anuncios-texto {
    div.view-content {
      div.views-row {
        padding: 10px;
      }
    }
  }

  div.view-content {
    display: flex;
    flex-flow: row nowrap;

    @media screen and (max-width: $screen-xs) {
      display: block;
    }

    @media screen and (max-width: $screen-sm) {
      max-width: 580px;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    div.views-row {
      background: rgba(255, 255, 255, .85);
      flex-grow: 1;
      flex-shrink: 1;
      width: 50%;

      @media screen and (max-width: $screen-xs) {
        width: 100%;
      }

      div.views-field-field-an-url {
        span {
          background-color: #EFC439;
          border-radius: 2px;
          color: #fff;
          font-size: 11px;
          padding: 0 2px;
          line-height: 14px;
          vertical-align: baseline;
        }

        a {
          color: #3400DC;
          font-family: Arial, Helvetica, sans-serif;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      div.views-field-field-an-texto1 {
        color: #0E8F0E;
        font-family: Arial, Helvetica, sans-serif;
      }

      div.views-field-field-an-texto2 {
        font-family: Arial, Helvetica, sans-serif;
      }
    }
  }
}

div.view-anuncios-banner-frontal {
  div.view-content {
    div.views-row {
      width: 100%;
    }
  }
}

/**
* Página principal
*/
body.front {
  div#main-wrapper {
    background: #e7e8e1;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;

    div#main {
      div#content-header {
        @include global-container;
      }

      div#main-content {
        div.section {
          ul.action-links {
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  }

  div#main-content {
    background: transparent !important;

    div.region.region-content {

      div.field-name-title {
        display: none;
      }

      div.field-name-field-content {
        div.field-item {
          h1 {
            border-left: 4px solid $gray-light;
            color: $gray-darker;
            font-size: $font-size-h3;
            font-weight: 700;
            margin: 10px 10px;
            padding-left: 10px;
          }

          div#contenedor-actualidad-inner {
            @include global-container;
            display: flex;
            flex-flow: row nowrap;

            @media screen and (max-width: $screen-sm) {
              display: block;
            }

            div.left {
              flex-grow: 0;
              flex-shrink: 1;
              max-width: $container-lg;
              width: 100%;
              @media screen and (max-width: $screen-sm) {
                width: 100%;
              }
            }

            div.right {
              flex-grow: 0;
              flex-shrink: 0;
              max-width: 210px;

              @media screen and (max-width: $screen-sm) {
                display: block !important;
                max-width: unset;
                width: 100%;
              }

              div.node-embed {
                & > div {
                  padding-left: 4px;
                }
              }

              img {
                height: auto !important;
                width: 100% !important;

                @media screen and (max-width: $screen-sm) {
                  display: block;
                  height: auto !important;
                  margin: 0 auto;
                  max-width: 100%;
                  width: auto !important;
                }
              }
            }
          }

          div#frontal-actualidad {
            position: relative;
            width: 100%;

            div.inner-container {
              display: flex;
              flex-flow: row wrap;
              position: relative;
              justify-content: center;

              div.views-row {
                @include front-view-row;

                &.view-noticias-frontal-embed {
                  div.tipo-publicacion {
                    background: #ff0066;
                    a {
                      color: white;
                    }
                  }
                }

                &.view-editorial-frontal-embed {
                  div.tipo-publicacion {
                    background: #ffcc00;
                    a {
                      color: white;
                    }
                  }
                }

                &.view-agenda-frontal-embed {
                  div.tipo-publicacion {
                    background: #ff0000;
                    a {
                      color: white;
                    }
                  }
                }

                &.view-agenda-frontal-embed-comunidad {
                  div.tipo-publicacion {
                    background: $brand-primary;
                    a {
                      color: white;
                    }
                  }
                }

                &.view-publicaciones {
                  &:before {
                    background: #8ebf73;
                    color: white;
                    content: 'Publicaciones';
                  }
                }
              }
            }
          }

          div#embed-responsivo {
            background: #c1c1bd;
            margin: 15px 0 0;
            padding-bottom: 20px;
            padding-top: 20px;

            @media screen and (max-width: $screen-sm) {
              margin-left: auto;
              margin-right: auto;
              max-width: 580px;
            }

            div.view.view-anuncios-web,
            div.view-anuncios-banner-frontal {
              @include global-container;
            }
          }

          div#contenedor-comunidad,
          div#contenedor-empresas {
            background: #dedede;
            padding-bottom: 30px;
            padding-top: 30px;

            div.contenedor-inner {
              @include global-container;
              display: flex;
              flex-flow: row nowrap;

              @media screen and (max-width: $screen-sm) {
                display: block;
              }

              div.izquierda,
              div.derecha {
                flex-grow: 0;

                @media screen and (max-width: $screen-sm) {
                  margin-left: auto;
                  margin-right: auto;
                  max-width: 580px;
                  padding-right: 0;
                  width: 100%;
                }
              }

              div.izquierda {
                width: 75%;

                @media screen and (max-width: $screen-sm) {
                  width: 100%;
                }
              }

              div.derecha {
                padding-left: 10px;
                width: 24%;

                @media screen and (max-width: $screen-sm) {
                  width: 100%;
                }
              }

              div#anuncio-imagen-frontal {
                height: 100%;
                position: relative;

                div.view.view-anuncios-web,
                div.view-anuncios-banner-frontal {
                  height: 100%;

                  div.view-content {
                    @include clearfix;
                    display: flex;
                    flex-flow: column nowrap;
                    height: 100%;
                    justify-content: space-around;

                    div.views-row {
                      @include clearfix;
                      background: #d7d8d2;
                      height: 50%;
                      padding: 5px;
                      margin-bottom: 5px;

                      @media screen and (max-width: $screen-sm) {
                        height: auto;
                      }

                      div, a {
                        @include clearfix;
                        align-items: center;
                        display: flex;
                        height: 100%;
                        justify-content: center;
                        width: 100%;
                      }

                      div.field-content {
                        a {
                          background: url("../images/logo.png");
                          background-position: center;
                          background-repeat: no-repeat;
                          background-size: auto 50px;
                          display: flex;
                          min-height: 200px;
                          text-align: center;
                        }
                      }
                    }
                  }
                }
              }

              div#anuncios-frontal {
                margin-top: 10px;
                position: relative;

                div.view.view-anuncios-frontal {
                  div.view-content {
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: space-between;
                    margin: 0 auto;

                    div.views-row {
                      @include front-view-row;
                      padding-bottom: 15px;

                      div.tipo-publicacion {
                        background: #d34eff;
                        a {
                          color: white;
                        }
                      }

                      div.views-field-field-foto {
                        min-height: 165px;
                      }
                    }
                  }
                }
              }
              //region Blog frontal
              div#blog-frontal {
                div.view-blog-frontal {
                  div.view-content {
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: space-between;
                    margin: 0 auto;

                    @media screen and (max-width: $screen-xs) {
                      display: block;
                    }

                    div.views-row {
                      @include front-view-row;
                      padding-bottom: 10px;

                      div.tipo-publicacion {
                        background: #ff6600;
                        a {
                          color: white;
                        }
                      }

                      div.views-field-php {
                        min-height: 80px;

                        .field-content {
                          background: url("../images/logo.png");
                          background-position: center;
                          background-repeat: no-repeat;
                          background-size: auto 50px;
                          display: block;
                          min-height: 80px;
                          text-align: center;
                          width: 100%;

                          a {
                            align-items: center;
                            display: flex;
                            height: 160px;
                            justify-content: center;
                            overflow: hidden;
                            width: 100%;

                            img {
                              display: block;
                              height: auto;
                              min-height: 50px;
                              width: 100%;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              //endregion Blog frontal
            }
          }

          div#contenedor-empresas {
            background: none;

            div#empresas-frontal {
              div.view-empresas-frontal {
                div.view-content {
                  display: flex;
                  flex-flow: row wrap;
                  justify-content: space-between;
                  margin: 0 auto;

                  @media screen and (max-width: $screen-xs) {
                    display: block;
                  }

                  div.views-row {
                    @include front-view-row;

                    div.tipo-publicacion {
                      background: #ff0066;
                      a {
                        color: white;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/**
* Estilos para listado de contenidos.
*/
body.listado-contenidos {
  div#block-system-main {
    padding: 10px;

    & > div.content {

      & > div.view {
        & > div.view-header {
          background: $gray-lighter;
          padding: 15px;
          margin-bottom: 20px;
        }

        & > div.view-content {
          display: flex;
          flex-flow: row wrap;
          position: relative;

          & > div.views-row {
            @include front-view-row;
            align-items: flex-start;
            background: #f3f3f3;
          }

          & > div.row-publicidad {
            margin: 15px auto;
            width: 80%;
          }
        }

        &.view-noticias {
          & > div.view-content {
            & > div.views-row {
              div.tipo-publicacion {
                background: #ff0066;
                a {
                  color: white;
                }
              }
            }
          }
        }

        &.view-empresas-frontal {
          & > div.view-content {
            & > div.views-row {
              div.tipo-publicacion {
                background: #ff0066;
                a {
                  color: white;
                }
              }
            }
          }
        }

        &.view-agenda {
          & > div.view-content {
            & > div.views-row {
              &.comunidad {
                div.tipo-publicacion {
                  background: $brand-primary;
                  a {
                    color: white;
                  }
                }
              }

              &.actualidad,
              &.Agenda-ORP,
              &.agenda-orp {
                div.tipo-publicacion {
                  background: red;
                  a {
                    color: white;
                  }
                }
              }
            }
          }
        }

        &.view-editoriales {
          & > div.view-content {
            & > div.views-row {
              div.tipo-publicacion {
                background: #ffcc00;
                a {
                  color: white;
                }
              }
            }
          }
        }

        &.view-publicaciones {
          & > div.view-content {
            & > div.views-row {
              padding-top: 20px;

              div.tipo-publicacion {
                background: #8ebf73;
                a {
                  color: white;
                }
              }
            }
          }
        }

        &.view-tablonanuncios {
          & > div.view-content {
            & > div.views-row {
              padding-top: 20px;
              div.tipo-publicacion {
                background: #d34eff;
                a {
                  color: white;
                }
              }
            }
          }
        }

        &.view-protocolos {
          & > div.view-content {
            & > div.views-row {
              padding-top: 20px;
              div.tipo-publicacion {
                background: #8ebf73;
                a {
                  color: white;
                }
              }
            }
          }
        }

        &.view-guias-tecnicas {
          & > div.view-content {
            & > div.views-row {
              padding-top: 25px;
              div.tipo-publicacion {
                background: #8ebf73;
                a {
                  color: white;
                }
              }
            }
          }
        }

        &.view-ntps {
          & > div.view-content {
            & > div.views-row {
              padding-top: 25px;
              div.tipo-publicacion {
                background: #8ebf73;
                a {
                  color: white;
                }
              }
              div.views-field-field-entradilla {
                a {
                  color: $brand-primary;

                  &:hover {
                    color: $link-hover-color;
                  }
                }
              }
            }
          }
        }

        &.view-directrices-oit {
          & > div.view-content {
            & > div.views-row {
              padding-top: 20px;
              div.tipo-publicacion {
                background: #8ebf73;
                a {
                  color: white;
                }
              }
            }
          }
        }

        &.view-carteles-anc {
          & > div.view-content {
            & > div.views-row {
              align-items: center;
              background: $gray-lighter;
              margin: 2px 2px;
              width: 24%;

              @media screen and (max-width: $screen-md) {
                width: 32%;
              }

              @media screen and (max-width: $screen-xs) {
                width: 48%;
              }

              div.tipo-publicacion {
                background: #8ebf73;
                a {
                  color: white;
                }
              }

              div.views-field-field-publ-imagen {
                min-height: unset;

                div.field-content,
                a {
                  height: auto;
                }
              }
            }
          }
        }

        &.view-blogs {
          & > div.view-content {
            & > div.views-row {
              div.tipo-publicacion {
                background: #ff6600;
                a {
                  color: white;
                }
              }

              div.views-field-php {
                min-height: 80px;

                .field-content {
                  background: url("../images/logo.png");
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: auto 50px;
                  display: block;
                  min-height: 80px;
                  text-align: center;
                  width: 100%;

                  a {
                    align-items: center;
                    display: flex;
                    height: 160px;
                    justify-content: center;
                    overflow: hidden;
                    width: 100%;

                    img {
                      display: block;
                      height: auto;
                      min-height: 50px;
                      width: 100%;
                    }
                  }
                }
              }
            }
          }

          &.view-display-id-page_1 {
            & > div.view-content {
              & > div.views-row {
                padding-top: 25px;

                div.tipo-publicacion {
                  background: #ff6600;
                  a {
                    color: white;
                  }
                }
              }
            }
          }
        }

        ul.pager-load-more {
          margin-top: 15px;

          li {
            display: block;
            margin: 0 auto;
            width: 100%;

            a {
              @include btn;
              @include btn-primary;
              @extend .btn-block;
            }
          }
        }
      }
    }
  }

  &.seccion-publicaciones,
  &.seccion-papers,
  &.seccion-bloggers,
  &.seccion-guias-tecnicas {
    div#block-system-main {
      & > div.content {
        & > div.view {
          & > div.view-content {
            & > div.views-row {
              @include clearfix;
              display: block;
              min-height: 150px;
              position: relative;
              width: 45%;

              @media screen and (max-width: $screen-sm) {
                width: 95%;
              }

              & > div.views-field {
                float: right;
                width: 65%;
              }

              & > div.views-field-field-organizaciones,
              & > div.views-field-field-tema {
                padding: 0 10px;
                margin-left: 0;
                margin-right: 0;
              }

              & > div.views-field-field-tema {
                width: 63%;
              }

              & > div.views-field-field-foto-paper-autor,
              & > div.views-field-field-publ-imagen,
              & > div.views-field-picture {
                background-size: auto 30px;
                float: left;
                position: relative;
                width: 30%;

                div.field-content {
                  height: auto;

                  a {
                    height: auto;

                    img {
                      max-height: 235px;
                      max-width: 100%;
                      height: auto;
                      width: auto;
                    }
                  }
                }
              }

              & > div.views-field-picture {
                text-align: center;

                div.field-content {
                  img {
                    max-width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.seccion-bloggers {
    div#block-system-main {
      & > div.content {
        & > div.view {
          & > div.view-content {
            & > div.views-row {
              padding-bottom: 15px;
              padding-left: 15px;
              padding-right: 15px;
            }
          }
        }
      }
    }
  }

  &.seccion-blogs {
    div#block-system-main {
      div.view.view-blogsblogger {
        & > div.view-content {
          display: flex;
          flex-flow: row wrap;
          position: relative;

          & > div.views-row {
            @include front-view-row;
            background: #f3f3f3;
            padding-bottom: 15px;

            &:before {
              background: #ff6600;
              content: 'Blog';
            }
          }

          & > div.row-publicidad {
            margin: 15px auto;
            width: 80%;
          }
        }

        ul.pager-load-more {
          margin-top: 15px;

          li {
            display: block;
            margin: 0 auto;
            width: 100%;

            a {
              @include btn;
              @include btn-primary;
              @extend .btn-block;
            }
          }
        }
      }

      div.field-name-field-url {
        font-size: $font-size-small;
        font-weight: 600;
        text-align: center;
      }
    }
  }

  &.seccion-papers {
    div#block-system-main {
      & > div.content {
        & > div.view {
          & > div.view-content {
            & > div.views-row {
              width: 24%;
              padding-bottom: 10px;

              @media screen and (max-width: $screen-md) {
                width: 33%;
              }

              @media screen and (max-width: $screen-sm) {
                height: auto;
                margin: 10px 2px !important;
                max-width: 100%;
                width: 49%;
              }

              @media screen and (max-width: $screen-xs) {
                margin: 10px 5px;
                width: 100%;
              }

              div.tipo-publicacion {
                background: red;
                a {
                  color: white;
                }
              }

              div.views-field-title {
                clear: both;
                margin-top: 27px;
                width: 100%;
              }

              div.views-field-field-foto-paper-autor {
                text-align: center;
                padding-left: 10px;
              }

              div.views-field-field-autor {
                font-size: $font-size-small;
                div.field-name-field-foto-paper-autor {
                  display: none;
                }

                div.field-name-field-nombre,
                div.field-name-field-apellidos {
                  display: inline-block;
                  padding-right: 5px;
                  font-weight: 600;
                }

                div.field-name-field-empresa {
                  font-style: italic;
                }
              }

              div.views-field-field-congreso {
                color: red;
                display: block;
                font-size: $font-family-base;
                font-weight: 700;
                max-width: 35%;
                min-width: 120px;
                padding: 2px 10px;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
              }

              div.views-field-field-keywords {
                clear: both;
                font-size: $font-size-small;
                padding: 0 10px;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  &.seccion-videos {
    div#block-system-main {
      div.views-exposed-form {
        padding: 0 10px;
      }
    }
  }

  &.seccion-empresas {

    div#block-system-main {
      padding-top: 0;
    }

    #main-content {
      background: none !important;
    }

    #empresas-destacadas-wrapper {
      border: 10px solid $gray-darker;
      background: $gray-darker;
      display: block;
      margin-left: -10px;
      margin-right: -10px;
      position: relative;

      @media screen and (max-width: $screen-sm) {
        display: none;
      }

      #empresas-destacadas-swiper.swiper-container {
        background: $gray-darker;
        position: relative;

        h2 {
          display: none;
          border: none !important;
          background: white;
          color: black;
          font-weight: bold;
          line-height: 1;
          margin: 0 !important;
          text-align: center;
          text-transform: uppercase;
          padding: 5px !important;
        }
        .swiper-wrapper {
          box-sizing: border-box;
          height: 220px;
          position: relative;

          .swiper-slide {
            box-sizing: border-box;
            height: 100%;
            position: relative;
            width: 25% !important;

            &:after {
              content: '';
              display: table;
              clear: both;
              width: 100%;
            }

            &.empresa-logo {
              box-sizing: border-box;
              height: 100%;
              padding: 10px;
              position: relative;

              &.empresa-color-0 {
                background: #7acadb;
              }
              &.empresa-color-1 {
                background: #1d9cd8;
              }
              &.empresa-color-2 {
                background: #e30d7f;
              }
              &.empresa-color-3 {
                background: #e61157;
              }
              &.empresa-color-4 {
                background: #facb13;
              }
              &.empresa-color-5 {
                background: #020303;
              }
              &.empresa-color-6 {
                background: white;
              }

              div.empresa-logo-inner {
                align-items: center;
                background: white;
                border-radius: 5px;
                box-sizing: border-box;
                display: flex;
                height: 100%;
                flex-flow: column nowrap;
                justify-content: center;
                padding: 5px;
                position: relative;
              }

              a {
                color: black;
                display: block;
                transition: color .6s ease-in-out;
              }

              &:hover {
                a {
                  color: #00A6C7 !important;
                }
              }
              a.linkbox {
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
              }

              a.image {
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                height: 70px;
                margin: 0 auto;
                width: 100%;
              }

              a.title {
                font-weight: bold;
                margin-top: 10px;
                text-align: center;
                width: 100%;
              }
            }
          }
        }
      }
    }

    div#empresas-view-container {
      & > div.view {
        div.view-filters {
          margin-left: -10px;
          margin-right: -10px;
        }

        div.views-exposed-form {
          display: flex;
          flex-flow: row wrap;
          padding: 15px 10px;
          width: 100%;
          div.views-exposed-widget {
            width: 24%;
            @media screen and (max-width: $screen-md) {
              width: 49%;
            }
            @media screen and (max-width: $screen-sm) {
              width: 24%;
            }
            input[type=text],
            select {
              width: 100%;
            }

            input[type=submit] {
              @include btn;
              @include btn-primary;
            }
          }
        }

        div.attachment-before {
          margin: 10px;
          margin-left: -10px;
          margin-right: -10px;
          padding: 5px 10px;

          span {
            &:after {
              content: '-';
              font-weight: 400;
              padding: 0 5px;
            }
            &:last-of-type {
              &:after {
                display: none;
              }
            }
          }
        }

        & > div.view-content {
          display: flex;
          flex-flow: row wrap;
          position: relative;

          & > div.views-row {
            @include front-view-row;
            align-items: flex-start;
            background: white;

            div.tipo-publicacion {
              background: $brand-highlight;
              a {
                color: white;
              }
            }
          }

          & > div.row-publicidad {
            margin: 15px auto;
            width: 80%;
          }

          div.views-field-field-tema,
          div.views-field-field-sector {
            margin-bottom: 0 !important;
            margin-top: 0 !important;
          }

          div.views-field-field-sector {
            font-size: $font-size-small;
            margin: 0 10px !important;
          }

          div.views-field-field-localizacion {
            font-size: $font-size-small;
            padding: 0 10px;
            div.adr {
              font-size: 0;
              span {
                font-size: $font-size-small;
              }
            }
            div.street-address,
            span.postal-code,
            span.locality,
            div.map-link {
              display: none;
            }
          }

          div.views-field-field-logo {
            margin-top: 0 !important;
            min-height: 80px;
            text-align: center;
            width: 100%;

            a,
            div.field-content {
              align-items: center;
              background: transparent url("../images/ico_empresa.png");
              background-position: center;
              background-repeat: no-repeat;
              background-size: auto 85px;
              display: flex;
              height: 160px;
              justify-content: center;
              overflow: hidden;
              position: relative;
              width: 100%;

              img {
                display: block;
                height: auto;
                min-height: 50px;
                max-height: 95%;
                width: auto;
                max-width: 95%;
              }
            }
            div.premium-false {
              background-color: #fafafa;
              a {
                display: none;
              }
            }

            div.premium-true,
            div.premium-true a {
              background-image: none !important;
            }

            & > img {
              display: block;
              height: auto;
              min-height: 50px;
              width: 100%;
            }

            &.has-video-embed,
            div.has-video-embed {
              a {
                &:before {
                  @extend .fa;
                  align-items: center;
                  border: 10px solid $brand-highlight;
                  border-radius: 100%;
                  content: '\f04b';
                  color: $brand-highlight;
                  display: flex;
                  font-size: $font-size-h1;
                  height: 80px;
                  justify-content: center;
                  left: 50%;
                  opacity: 0.75;
                  position: absolute;
                  top: 50%;
                  transform: translateX(-50%) translateY(-50%);
                  width: 80px;
                }
              }
            }
          }
        }

        ul.pager-load-more {
          margin-top: 15px;

          li {
            display: block;
            margin: 0 auto;
            width: 100%;

            a {
              @include btn;
              @include btn-primary;
              @extend .btn-block;
            }
          }
        }
      }
    }
  }
}

/**
* Foros
*/
body.node-type-forum,
body.seccion-foros,
body.page-forum {
  div#block-system-main {
    padding: 5px 15px 15px;

    span.forum-list-icon-wrapper {
      background: $brand-primary;
      background: linear-gradient(to bottom, $brand-primary 0%, $brand-success 100%);
      box-shadow: none;
      border-radius: 0;
    }

    a.af-button-large,
    a.af-button-small {
      border: none;
      border-radius: 0;
      box-shadow: none;
      margin: 10px 0;
      @include btn;
      @include btn-primary;
      line-height: 2 !important;
      padding-bottom: 0;
      padding-top: 0;

      span {
        text-shadow: none;
      }
    }

    div.forum-post-links {
      width: 100%;

      ul {
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        margin: 0;
        width: 100%;

        li {
          * {
            line-height: 1;
          }

          a {
            @media screen and (max-width: $screen-xs) {
              display: block;
              text-align: center;
              width: 100%;
            }
          }
        }
      }
    }

    div#forum-comments {
      background: $gray-lighter;
      padding: 15px;
      margin: 10px 0;

      h2 {
        border-top: 2px solid $gray;
        font-size: $font-size-h4;
        font-weight: 300;
        margin: 0;
        margin-top: 25px;
        padding-top: 25px;
      }

      label {
        font-weight: 300;
      }

      input[type=submit] {
        @include btn;
        @include btn-primary;
        border: none;
      }

      .form-item,
      .form-actions {
        margin: 0;
        margin-top: 10px;
      }
    }

    div#forum {
      table {
        &.forum-table-topics {
          margin-bottom: 0;
        }

        tbody {
          tr {
            td {
              padding-left: 10px;
              padding-right: 10px;
            }
          }
        }
      }
    }

    div.topic-post-count {
      float: none;
      font-size: $font-size-small;
      margin: 0;
      text-align: right;
      width: 100%;
    }

    div.topic-reply-link,
    div.last-post-link {
      display: inline-block;
      float: left;
      margin: 0 5px 2px 0;

      @media screen and (max-width: $screen-xs) {
        width: 100%;
      }

      a {
        margin: 0;

        @media screen and (max-width: $screen-xs) {
          display: block;
          width: 100%;
        }
      }
    }

    div.forum-post-wrapper {
      div.forum-post-panel-sub {
        width: 80px;

        div.user-picture {
          img {
            height: auto;
            width: 100%;
          }
        }
      }

      div.forum-post-panel-main {
        margin-left: 80px;
      }
    }
  }
}

/**
* Anuncios header.
*/
div.view-anuncios-web.view-id-anuncios_web.view-display-id-embed_1 {
  // Lo que hacemos es mostrar un formato de anuncio u otro en función de la anchura
  // de la página...
  .views-field-field-an-img-home {
    display: block;

    @media screen and (max-width: $screen-xs) {
      display: none;
    }
  }

  .views-field-field-an-img-lat {
    display: none;

    @media screen and (max-width: $screen-xs) {
      display: block;
    }
  }
}

body.page-user {
  .profile .user-picture {
    position: relative;
    z-index: 1;
  }

  div.view-mis-comentarios,
  div.view-listados-user {
    background-color: white;
    padding: 0 5px;
    h2.title {
      font-size: $font-size-large;
      font-weight: 600;
      padding-top: 15px;
      text-align: center;
    }
    div.view-filters {
      div.views-exposed-widgets {
        div.views-exposed-widget {
          float: none;
          display: inline-block;
          margin: 0 2px;
          @media screen and (max-width: $screen-sm) {
            width: 100%;
          }
          & > div {
            display: inline-block;
          }
        }
        div.views-submit-button {
          margin: 0 2px;
          padding: 0;
          input {
            @include btn;
            @include btn-primary;
            margin: 0;
            padding: 2px 10px;
          }
        }
      }
    }
    div.view-content {
      width: 100%;
      table {
        thead {
          tr {
            th {
              text-align: center;
            }
          }
        }
        tbody {
          tr {
            td {
              padding: 2px 5px;
              &.views-field-type {
                font-style: italic;
                font-size: $font-size-small;
              }
              &.views-field-edit-node {
                font-size: $font-size-small;
              }
              &.views-field-created {
                font-style: italic;
                font-size: $font-size-small;
                text-align: center;
              }
              &.views-field-title {
              }
            }
          }
        }
      }
    }
    div.item-list {
      width: 100%;
      ul.pager {
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        width: 100%;
        li {
          align-items: center;
          display: flex;
          justify-content: center;
          margin: 0;
          padding: 2px;
          &.pager-current {
            padding: 5px 14px;
          }
          a {
            border-radius: 0 !important;
          }
        }
      }
    }
  }
}

body.page-buscador {
  div#main-content {
    overflow-x: hidden;

    div#block-system-main {

      & > div.content {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-left: 0 !important;
        min-height: 300px;
        overflow: hidden;

        @media screen and (max-width: $screen-sm) {
          display: block;
          min-height: unset;
        }

        div.view-buscador {
          margin: 0 !important;
          width: 100%;

          div#header-logo-container {
            align-items: center;
            display: flex;
            justify-content: center;
            padding: 0 0 10px;

            div.header-logo {
              background: url("../images/logoPI.png");
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              height: 100px;
              max-width: 230px;
              width: 100%;
            }
          }

          div.attachment.attachment-before {
            div.align-right {
              font-size: $font-size-small;
              font-style: italic;
              margin: 20px 0 0;
              padding: 0 20px;
              text-align: right;
            }
          }

          div.view-filters {
            div.views-exposed-form {
              div.views-exposed-widgets {
                margin: 0;
                div.views-exposed-widget {
                  float: none;
                  margin: 0;
                  padding: 0;
                }

                div.views-widget-filter-search_api_views_fulltext {
                  background: $brand-primary;
                  padding: 10px 15px;
                  width: 100%;
                  div.form-type-textfield {
                    position: relative;
                    &:after {
                      @extend .fa;
                      content: '\f002';
                      position: absolute;
                      right: 5px;
                      transform: translateY(-50%);
                      top: 50%;
                    }
                    input[type=text] {
                      border: none;
                      display: block;
                      padding: 10px 30px 10px 10px;
                      width: 100%;
                    }
                  }
                }

                div.views-widget-filter-type {
                  border-bottom: 1px solid $gray-light;
                  display: flex;
                  flex-flow: row nowrap;
                  margin: 0 auto;
                  padding: 10px 0;
                  position: relative;
                  width: 97%;

                  & > label {
                    display: none;
                  }

                  & > label,
                  label[for="edit-type"] {
                    font-weight: bold;
                    position: relative;
                    top: 2px;
                    width: 140px;
                  }

                  div.views-widget {
                    width: auto;
                    div.form-type-select {
                      width: 100%;
                      div.form-checkboxes {
                        width: 100%;
                        div.bef-checkboxes {
                          display: flex;
                          width: 100%;
                          & > div {
                            padding-right: 20px;
                          }
                          label {
                            font-weight: 300;
                          }
                        }
                      }
                    }
                  }
                }

                div.views-submit-button {
                  height: 0 !important;
                  width: 0 !important;
                  overflow: hidden;
                }
              }
            }
          }

          div.view-content {
            padding: 10px 15px;

            div.views-row {
              @include clearfix;
              display: block;
              margin-bottom: 10px;
              min-height: 150px;
              overflow: hidden;
              padding: 10px 15px;
              position: relative;
              width: 100%;

              &.views-row-even {
                background: #F0F0F0;
              }

              div.views-field-title {
                h2.field-content {
                  font-size: $font-size-large;
                  margin-top: 0;
                  @media screen and (max-width: $screen-sm) {
                    font-size: $font-size-large;
                  }
                }
              }

              div.views-field-type,
              div.views-field-field-fecha-publicacion {
                color: $gray-light;
                display: inline-block;
                padding: 0 5px 0 0;
              }

              div.views-field-field-fecha-publicacion {
                border-left: 1px solid $gray-light;
                padding: 0 8px;
              }
            }
          }

          div.item-list {

            ul.pager-load-more {
              margin-top: 15px;

              li {
                display: block;
                margin: 0 auto;
                width: 100%;

                a {
                  @include btn;
                  @include btn-primary;
                  @extend .btn-block;
                }
              }
            }
          }
        }
      }
    }
  }
}

body.page-tematica {
  div.view-vista-tematica {

    h1 {
      border-left: 4px solid $gray-light;
      color: $gray-darker;
      font-size: $font-size-h3;
      font-weight: 700;
      margin: 10px 10px;
      padding-left: 10px;
    }

    div.view-content {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      margin: 0 auto;
      div.views-row {
        @include front-view-row;
        &.blog {
          div.tipo-publicacion {
            background: #ff6600;
            a {
              color: white;
            }
          }

          div.views-field-field-fecha-publicacion {
            display: none;
          }
        }
        &.noticia {
          div.tipo-publicacion {
            background: #ff0066;
            a {
              color: white;
            }
          }
          div.views-field-field-fecha-publicacion,
          div.views-field-field-apellidos {
            display: none;
          }
        }
        &.publicación {
          div.tipo-publicacion {
            background: #8ebf73;
            a {
              color: white;
            }
          }
          div.views-field-field-fecha-publicacion,
          div.views-field-field-apellidos {
            display: none;
          }
        }
        &.editorial {
          div.tipo-publicacion {
            background: #ffcc00;
            a {
              color: white;
            }
          }
          div.views-field-field-fecha-publicacion,
          div.views-field-field-apellidos {
            display: none;
          }
        }
      }
    }
    ul.pager-load-more {
      margin-top: 15px;

      li {
        display: block;
        margin: 0 auto;
        width: 100%;

        a {
          @include btn;
          @include btn-primary;
          @extend .btn-block;
        }
      }
    }
  }
}

body.page-cart,
body.page-legal-accept {
  div#block-system-main {
    div.content {
      padding: 15px;
    }
  }
}
