//#admin-menu-wrapper {
//  display: none;
//}
body.not-logged-in {
  section#block-system-user-menu {
    ul.menu {
      li {
        border: none !important;
        padding-right: 3px !important;
      }
    }
  }
}

.headerFixed {
  border-bottom: none;
  height: auto;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 9999;
}

div#page {
  header#header {
    background: white;
    padding: 0;
    width: 100%;
    // region Top header
    div#pre-header-container {
      @include global-container;
      align-items: center;
      background: white;
      display: flex;
      flex-flow: row nowrap;
      height: 112px;
      margin: 0 auto;

      @media screen and (max-width: $screen-sm) {
        display: none;
      }

      @media screen and (max-width: $screen-xs) {
        border-bottom: none;
        height: auto;
      }

      & > * {
        flex-grow: 1;
        flex-shrink: 1;
      }

      a#logo {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        height: 100%;
        justify-content: center;
        min-width: 110px;
        width: 255px;
        flex-grow: 0;
        flex-shrink: 0;

        @media screen and (max-width: $screen-sm) {
          width: 110px;
        }

        img {
          display: block;
          max-width: 100%;
          max-height: 100%;
          min-width: 90px;
          transition: all 0.6s ease-in-out;

          @media screen and (max-width: $screen-sm) {
            max-width: 100%;
            max-height: 100%;
            min-width: 90px;
          }
        }
      }

      div.region.region-preheader {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        flex-grow: 1;
        height: 100%;
        position: relative;

        div.BlockMiembrosPI {
          border-left: 1px solid $gray-lighter;
          color: $gray;
          flex-grow: 0;
          flex-shrink: 0;
          font-size: $font-size-small;
          font-weight: 100;
          max-width: 350px;
          padding-left: 4px;

          @media screen and (max-width: $screen-sm) {
            display: none;
          }
        }

        section.block.block-system.block-menu {
          align-items: center;
          display: flex;
          flex-grow: 1;
          flex-flow: row nowrap;
          height: 100%;
          justify-content: flex-end;
          padding: 5px 0;
          position: relative;

          & > h2 {
            display: none;
          }

          & > div.content {
            ul.menu {
              align-items: flex-end;
              display: flex;
              flex-flow: row wrap;
              justify-content: flex-end;
              margin: 0;
              padding: 0;

              @media screen and (min-width: $screen-sm) {
                position: relative;
                top: 20px;
              }

              @media screen and (max-width: $screen-xs) {
                flex-flow: column;
                padding: 0;
              }

              li {
                border-left: 1px solid $gray-light;
                list-style: none;
                margin: 0;
                padding: 0;

                @media screen and (max-width: $screen-sm) {
                  border: none !important;
                }

                &:first-of-type {
                  border-left: none;
                }

                @media screen and (max-width: $screen-xs) {
                  font-size: $font-size-small;
                  flex-grow: 0;
                  flex-shrink: 0;
                  text-align: right;
                }

                a {
                  color: $brand-primary;
                  font-size: $font-size-small;
                  padding: 5px 8px;
                  text-transform: uppercase;
                  text-decoration: none;

                  &:hover {
                    background: lighten($gray-lighter, 25%);
                  }

                  &[href*="user/login"],
                  &[href*="user/register"] {
                    @include btn;
                    @include btn-primary;
                    padding: 2px 5px;
                    font-size: small;
                    text-transform: none;
                  }

                  @media screen and (max-width: $screen-xs) {
                    border: none;
                    display: block;
                    margin-bottom: 3px;
                    padding: 2px 5px;
                  }
                }
              }
            }
          }
        }

        div#block-block-9 {
          position: absolute;
          top: 20px;
          right: 0;
          width: 100%;

          @media screen and (max-width: $screen-sm) {
            display: none;
          }

          div.content {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;

            a {
              margin: 0 5px;

              img {
                height: auto !important;
                width: 25px !important;
              }
            }
          }
        }
      }
    }
    // endregion
    // region Mobile bar
    div#mobile-bar {
      box-shadow: 0 6px 4px -4px #E8E8EC;
      background: black;
      min-height: 44px;
      margin-right: -2px;
      border-top: solid 2px #E8E8EC;

      .mobile-bar-logo {
        height: 28px;
        margin-top: 6px;
        margin-left: 5px;
      }

      .mobile-bar-action {
        height: 16px;
        margin-top: 12px;
        margin-right: 8px;
        float: right;
      }

      @media screen and (min-width: $screen-sm) {
        display: none;
      }

      button.navbar-toggle {
        padding: 0;
        margin-top: 9px;
        margin-right: 5px;
        float: left;
        margin-left: 12px;

        span.icon-bar {
          background: white;
        }
      }
    }
    // endregion
    // region First menu-bar container
    div#header-container {
      background: black;
      position: relative;
      width: 100%;

      @media screen and (min-width: $screen-sm) {
        display: block !important;
        height: auto !important;
      }

      @media screen and (max-width: $screen-sm) {
        position: relative;
        left: 0;
        top: 0;
        transform: none;
        overflow: hidden;
      }

      div.container {
        display: flex;
        flex-flow: row nowrap;
        position: relative;

        @media screen and (max-width: $screen-sm) {
          flex-flow: column nowrap;
          padding: 0;
        }

        div.region.region-header {
          flex-grow: 1;
          position: relative;
          z-index: 2;
          width: 100%;

          & > section {
            display: none;
          }
          // Menu primario
          div.block.block-menu-block {
            height: 30px;
            width: 100%;

            @media screen and (max-width: $screen-sm) {
              height: auto;
            }

            & > div.content {
              height: 100%;
              position: relative;

              div.menu-block-wrapper {
                @media screen and (max-width: $screen-sm) {
                  height: auto;
                }

                &.menu-level-1 {
                  position: relative;

                  & > ul.menu {
                    display: flex;
                    flex-flow: row wrap;
                    font-size: $font-size-small;
                    max-height: 30px;
                    margin: 0;
                    padding: 0;
                    overflow: hidden;
                    transition: max-height 1s ease-in-out;
                    z-index: 5;

                    @media screen and (max-width: $screen-sm) {
                      flex-flow: column nowrap;
                      height: auto;
                      max-height: 50vh;
                      padding-right: 0 !important;
                      overflow-y: scroll;
                      overflow-x: hidden;

                      &:after {
                        display: none;
                      }
                    }

                    li {
                      display: block;
                      flex-grow: 1;
                      flex-shrink: 0;
                      min-height: 30px;
                      list-style: none;
                      margin: 0;
                      padding: 0;
                      text-align: center;

                      &.menu-inner {
                        & > a,
                        & > span.nolink {
                          &:before {
                            content: '\f0da';
                            font-family: FontAwesome;
                            padding-right: 10px;

                            @media screen and (max-width: $screen-sm) {
                              content: '\f0d7';
                            }
                          }

                          @media screen and (max-width: $screen-sm) {
                            padding-bottom: 15px !important;
                          }
                        }
                      }

                      @media screen and (max-width: $screen-sm) {
                        border-bottom: 1px dotted $gray-dark;
                        padding: 10px 0;
                        position: relative;
                        text-align: left;
                        width: 100%;

                        a,
                        & > span.nolink {
                          padding-left: 15px !important;
                        }

                        &.menu-inner {
                          padding-bottom: 0;
                        }

                        .touch & {
                          &:hover,
                          &:focus {
                            background: $gray-light;
                            position: relative;
                          }
                        }
                      }

                      @media screen and (min-width: $screen-sm) {
                        &:hover {
                          ul {
                            display: flex !important;
                            min-height: inherit !important;
                            position: absolute;
                            top: 100%;
                            left: 0;
                          }
                        }
                      }

                      &.active {
                        border-bottom: 4px solid $brand-primary;
                        box-sizing: border-box;

                        @media screen and (max-width: $screen-sm) {
                          border: none;
                        }

                        @media screen and (max-width: $screen-lg) {
                          background: transparentize($gray-lighter, .65);
                        }
                      }

                      a,
                      & > span.nolink {
                        color: white;
                        cursor: pointer;
                        display: block;
                        padding: 5px 8px;
                        text-decoration: none;
                      }

                      //Formacion
                      &.menu-mlid-6697 {
                        background: #0ea9d0;
                        a {
                          align-items: center;
                          display: flex;
                          color: black;
                          justify-content: center;
                          font-weight: 600;
                          padding: 5px 8px 5px 8px;
                          &:before {
                            background: url("../images/MindMap.png");
                            background-size: contain;
                            background-position: center;
                            background-repeat: no-repeat;
                            display: block;
                            content: '';
                            height: 20px;
                            margin-right: 5px;
                            width: 20px;
                          }
                        }
                      }

                      ul {
                        background: $gray-darker;
                        display: none;
                        flex-flow: row wrap;
                        justify-content: flex-start;
                        max-height: inherit;
                        overflow-x: hidden;
                        padding: 0 10px;
                        transition: all 1.2s ease-in-out;
                        width: 100%;

                        @media screen and (min-width: $screen-sm) {
                          padding-top: 5px;
                          padding-bottom: 5px;
                        }

                        @media screen and (max-width: $screen-sm) {
                          display: block !important;
                          height: auto;
                          padding: 0;
                        }

                        li {
                          flex-grow: 0;
                          min-width: 20%;

                          @media screen and (max-width: $screen-sm) {
                            border-bottom: 1px dotted white;

                            &:last-of-type {
                              border-bottom: none;
                            }
                          }

                          a {
                            color: white;

                            &:hover {
                              text-decoration: underline;
                            }
                          }
                        }
                      }
                    }

                    & > li > a,
                    & > li > span.nolink {
                      font-weight: 600 !important;
                    }

                    @media screen and (min-width: $screen-sm) {
                      &.menu-expanded {
                        background: white;
                        border: 5px solid darken($brand-primary, 10%);
                        border-top: 0;
                        flex-flow: column nowrap;
                        max-height: 1000px;
                        position: relative;
                        z-index: 10;

                        & > li {
                          background: transparent !important;
                          border: none;
                          border-top: 1px dotted $gray-lighter;
                          color: $gray-base;
                          text-align: left;

                          & > a,
                          & > span.nolink {
                            color: $gray-base;
                          }

                          &.menu-inner {
                            & > a,
                            & > span.nolink {
                              &:before {
                                content: '\f0d7';
                              }
                            }
                          }

                          &:hover {
                            ul {
                              position: relative;
                              top: 0;
                              left: 0;
                            }
                          }

                          ul {
                            display: flex !important;
                            max-height: inherit;
                            min-height: inherit;
                            overflow: visible;
                            position: relative;
                            top: 0;
                            left: 0;

                            li {
                              border: none;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          div#block-block-9 {
            display: none;
          }
        }

        a#more-menu {
          display: none;
        }

        div.region.region-header-inner {
          height: 30px;
          max-width: 230px;
          position: relative;
          overflow: hidden;
          width: 30%;

          @media screen and (max-width: $screen-sm) {
            margin: 10px 0;
            max-width: unset;
            width: 100%;
          }

          div.BlockBusqueda {
            h2 {
              display: none;
            }

            div.content {
              padding: 0 0 0 10px;

              @media screen and (max-width: $screen-sm) {
                padding: 0;
              }

              form {
                div {
                  position: relative;

                  @media screen and (max-width: $screen-sm) {
                    margin: 0 auto;
                    width: 85%;
                  }

                  div.form-item {
                    margin: 0;
                    display: inline-block;
                    width: 100%;

                    label {
                      display: none;
                    }

                    input[type=text] {
                      border: none;
                      border-radius: 1px;
                      display: block;
                      height: 26px;
                      margin-top: 2px;
                      padding: 0 35px 0 8px;
                      width: 100%;
                    }
                  }

                  div.form-actions {
                    position: static;
                  }

                  input[type=submit] {
                    background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMS4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDQ1MSA0NTEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ1MSA0NTE7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4Ij4KPGc+Cgk8cGF0aCBkPSJNNDQ3LjA1LDQyOGwtMTA5LjYtMTA5LjZjMjkuNC0zMy44LDQ3LjItNzcuOSw0Ny4yLTEyNi4xQzM4NC42NSw4Ni4yLDI5OC4zNSwwLDE5Mi4zNSwwQzg2LjI1LDAsMC4wNSw4Ni4zLDAuMDUsMTkyLjMgICBzODYuMywxOTIuMywxOTIuMywxOTIuM2M0OC4yLDAsOTIuMy0xNy44LDEyNi4xLTQ3LjJMNDI4LjA1LDQ0N2MyLjYsMi42LDYuMSw0LDkuNSw0czYuOS0xLjMsOS41LTQgICBDNDUyLjI1LDQ0MS44LDQ1Mi4yNSw0MzMuMiw0NDcuMDUsNDI4eiBNMjYuOTUsMTkyLjNjMC05MS4yLDc0LjItMTY1LjMsMTY1LjMtMTY1LjNjOTEuMiwwLDE2NS4zLDc0LjIsMTY1LjMsMTY1LjMgICBzLTc0LjEsMTY1LjQtMTY1LjMsMTY1LjRDMTAxLjE1LDM1Ny43LDI2Ljk1LDI4My41LDI2Ljk1LDE5Mi4zeiIgZmlsbD0iIzAwMDAwMCIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);
                    background-position: center;
                    background-size: 50% 50%;
                    background-repeat: no-repeat;
                    border: none;
                    color: white;
                    display: block;
                    font-size: 0;
                    height: 24px;
                    position: absolute;
                    right: 1px;
                    top: 3px;
                    width: 26px;

                    &:after {
                      content: 'H';
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    // endregion
    // region Second menu-bar container
    div#post-header-container {
      display: none !important;
      position: relative;
      top: -28px;
    }
    // div#post-header-container
    // endregion
  }
}
