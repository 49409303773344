div#footer-pre {
  @include global-container;
  margin: 0 auto 10px;
  div.region.region-footer-pre {
    background: rgba(255, 255, 255, 0.9);
    padding: 10px;
    div.block {
      div.content {
        min-height: 50px;
        position: relative;
        div.titulo {
          background: $brand-primary;
          position: absolute;
          right: 0;
          width: 30%;
          z-index: 1 !important;
          @media screen and (max-width: $screen-sm) {
            width: 80%;
          }
          h2 {
            color: white;
            font-size: $font-size-base;
            margin: 0;
            padding: 5px;
            text-align: center;
            text-transform: uppercase;
            a{
              color: white;
            }
          }
        }
        div.lista-empresas {
          align-items: center;
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
          padding: 40px 0 0;
          position: relative;
          div.empresas-con-pi {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            height: 60px;
            margin: 2% 2%;
            position: relative;
            width: 12%;
            @media screen and(max-width: $screen-sm) {
              margin-top: 5px;
              width: 29%;
            }
            a {
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

footer#footer {
  @extend .container-fluid;
  float: none !important;
  margin: 20px 0 0 !important;
  padding: 20px 20px 0;
}

div#footer-top {
  @include global-container;
  padding: 15px;

  div.region.region-footer-top {
    background-attachment: fixed;
    background-image: url("../images/bgtexture.jpg");
    background-position: center;
    background-size: cover;
    border-bottom: 10px solid $gray-base;
    border-top: 10px solid $gray-base;
    padding: 0;

    div.BlockUserSubscribeFooter {
      background: transparentize($gray-base, .45);
      margin: 35px;
      padding: 25px;
      @media screen and (max-width: $screen-sm) {
        margin: 0;
        padding: 10px;
      }

      form.FormUserSubscribeFooter {

        div.subject-container {
          color: white;
          font-family: $font-family-serif;
          font-size: $font-size-h1;
          font-weight: 800;
          margin-bottom: 20px;
          text-align: center;
          text-shadow: 2px 2px 2px $gray-base;

          @media screen and (max-width: $screen-sm) {
            font-size: $font-size-large;
          }
        }

        a {
          color: white;
          font-weight: 700;
          text-decoration: none;
          &:hover {
            color: white;
          }
        }

        div.element-container:not(.element-container-bottom) {
          align-items: center;
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          @media screen and (max-width: $screen-sm) {
            display: block;
          }

          & > div {
            margin: 1%;
            text-align: center;
            width: 31%;
            @media screen and (max-width: $screen-sm) {
              width: 100%;
            }

            label {
              color: white;
              font-weight: 300;
            }

            input {
              background: rgba(255, 255, 255, .8);
              border: none;
              padding: 5px;
              width: 100%;

              &.form-submit {
                background: rgba(255, 255, 255, 1);
                position: relative;
                margin-top: 24px;
                &:hover{
                  background: rgba(255, 255, 255, .7);
                }
              }
            }
          }
        }
        div.element-container-bottom {
          margin-top: 10px;

          .form-item {
            margin-bottom: 0;
          }

          div {
            text-align: center;
          }

          label {
            color: white;
            font-weight: 300;
          }

          input {
            margin-top: -2px;
            width: auto;
          }
        }
      }
    }
  }
}

footer#footer {
  background: transparentize($gray-base, 0.10);
  border-top: 10px solid $gray-base;
  color: white;
  margin: 0;
  padding: 0;

  div#footer-columns {
    padding-top: 10px;
    @include global-container;
    display: flex;
    flex-flow: row nowrap;
    @media screen and (max-width: $screen-sm) {
      display: block;
    }
    div.region {
      border-bottom: 1px solid $gray-dark;
      flex-grow: 1;
      flex-shrink: 0;
      font-size: $font-size-small;
      margin: 0 1%;
      width: 23%;

      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }

      @media screen and (max-width: $screen-sm) {
        margin-bottom: 10px;
        width: 100%;
      }

      a {
        color: white;
        text-decoration: none;
      }
      section.block {
        &.block-menu {
          ul.menu {
            padding: 0;
            li {
              list-style: none;
              margin: 0;
              padding: 0;
            }
          }
        }
        h2 {
          border-bottom: 1px solid $gray-dark;
          font-size: $font-size-large * 0.9;
          font-family: $font-family-serif;
          font-weight: 700;
          margin: 0;
          padding-bottom: 2px;
          text-align: center;
          text-transform: uppercase;
          &:first-letter {
            font-size: $font-size-large;
          }
        }
        div.content {
          font-size: $font-size-small !important;
          margin: 2px;
          padding: 15px;
          .small {
            font-size: 100%;
          }
          @media screen and (max-width: $screen-sm) {
            background: $gray-darker;
          }
        }
      }
    }
  }
  div.section {
    @include global-container;
    div.region.region-footer {
      div.block {
        &.block-menu {
          margin-top: 15px;
          @media screen and (max-width: $screen-sm) {
            margin: 0;
          }

          ul.menu {
            align-items: center;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            margin: 0;
            padding: 0;
            @media screen and (max-width: $screen-sm) {
              display: block;
            }
            li {
              border-right: 1px solid $gray-dark;
              list-style: none;
              margin: 0;
              padding: 0 10px;
              text-decoration: none;
              &:last-of-type {
                border: none;
              }
              @media screen and (max-width: $screen-sm) {
                border: none;
                padding: 0;
                text-align: center;
              }
              a {
                color: white;
                font-size: $font-size-small * 0.8;
                text-decoration: none;
                &:hover {
                  color: $gray-lighter;
                }
              }
            }
          }
        }
        &.block-nodeblock {
          margin-top: 15px;
          &:before {
            border-top: 1px solid $gray-dark;
            content: '';
            display: block;
            margin: 0 auto;
          }
          div.content {
            font-size: $font-size-small * .8;
            padding-top: 10px;
            text-align: center;
          }
        }
      }
    }
  }
}
