html {
  overflow: scroll;
}

table {
  margin: 0 0 10px;
  width: 100%;
  th, td {
    padding: 2px $table-cell-padding !important;
  }
}

@mixin clearfix {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

@mixin global-container {
  @extend .container;
}

@mixin theme-container($gutter: $grid-gutter-width) {
  @include clearfix;
  margin-right: auto;
  margin-left: auto;
  padding-left: floor(($gutter / 2));
  padding-right: ceil(($gutter / 2));
  width: 100%;

  @media (min-width: $screen-sm-min) {
    max-width: $container-sm !important;
  }

  @media (min-width: $screen-md-min) {
    max-width: $container-md !important;
  }

  @media (min-width: $screen-lg-min) {
    max-width: $container-lg !important;
  }
}

@mixin btn {
  @extend .btn;
}

@mixin btn-default {
  @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
}

@mixin btn-primary {
  @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}

// Success appears as green
@mixin btn-success {
  @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}

// Info appears as blue-green
@mixin btn-info {
  @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}

// Warning appears as orange
@mixin btn-warning {
  @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}

// Danger and error appear as red
@mixin btn-danger {
  @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}

div.messages,
div.tabs,
ul.action-links {
  @include theme-container;
}

.messages {
  @extend .alert;
  background-image: none;
  font-size: small;
  padding: 10px !important;
  margin: 15px auto !important;
  width: 90% !important;

  &.status {
    @extend .alert-success;
    background-image: none;
  }

  &.warning {
    @extend .alert-warning;
    background-image: none;
  }

  &.error {
    @extend .alert-danger;
    background-image: none;
  }
}

.cabecera-congreso {
  width: 100%;
}

body.p0-congresos {
  .content {
    padding: 0px 20px 0px 20px;
  }

  div.tabs {
    display: flex;
    justify-content: center !important;

    .tabs.primary {
      li {
        height: auto;

        a {
          font-size: 23px !important;
        }
      }
    }
  }

  div.form-type-checkboxes {
    .form-item {
      display: inline-block;
    }
  }
}

div.addthis_inline_share_toolbox {
  margin-top: 15px;
  margin-bottom: 10px;
}

// Esconder iconos del feed.
.feed-icon {
  display: none;
}

#cboxOverlay,
#colorbox {
  z-index: 999999 !important;
}

.at-custom-mobile-bar {
  z-index: 99990 !important;
}

#sliding-popup {
  z-index: 99999 !important;
}

.gmap img,
.view-gmap img,
.openlayers-map img,
#getlocations_map_canvas img {
  max-width: none;
}